/**
 * @application  Fxp
 */
/**
 * @module Fxp.Controllers
 */
declare type IStateService = any;
import { ILogger } from "../interfaces/ILogger";
import { FxpMessageService } from "../../app/banner/FxpMessageService";
import {
  FxpConstants,
  CustomEvents,
  ApplicationConstants,
} from "../common/ApplicationConstants";
import { OBOUserService } from "../services/OBOUserService";
import { IRootScope } from "../interfaces/IRootScope";
import { UserProfileService } from "../services/userProfileService";
import { FxpConfigurationService } from "../services/FxpConfiguration";
import { FxpStateTransitionService } from "../services/FxpStateTransitionService";
import { CommonUtils } from "../utils/CommonUtils";
import { IAppControllerScope } from "../interfaces/IAppControllerScope";
import { FxpBreadcrumbService } from "../services/FxpBreadcrumbService";
import { pageTourEventService } from "../services/pageTourEventService";
import { FxpBotService } from "../provider/FxpBotServiceProvider";
import { PlannedDownTimeService } from "../../app/systemMessages/PlannedDownTimeService";
import { FxpUIData } from "../factory/FxpUIDataFactory";
import { AppControllerHelper } from "../factory/AppControllerHelper";
import { SessionTimeoutModalFactory } from "../factory/SessionTimeoutModalFactory";
import { FxpBroadcastedEvents } from "../services/FxpBroadcastedEvents";
import { RejectType } from "@uirouter/core";
import { UserInfoService } from "./../services/UserInfoService";
import { IfObservable } from "rxjs/observable/IfObservable";
import { FxpSignalRService } from "../services/FxpSignalRClient";
import {
  ActionStatus,
  ActionType,
  ComponentType,
  EventName,
} from "@microsoftit/telemetry-extensions-npm";
import { SystemEvent } from "../telemetry/SystemEvent";
import { TelemetryConstants } from "../telemetry/TelemetryConst";
import { FxpGlobalStoreService } from "../services/fxp.global.store.service";
import { ShowLoader } from "../../app/loader/loader.actions";
import { SimpleProvider } from "@microsoft/mgt";
import { ErrorCodes } from "../constants/errorCodes";
import { ErrorSeverityLevel } from "../telemetry/ErrorSeverityLevel";
import { FeatureUsageEvent } from "../telemetry/FeatureUsageEvent";
import { SharedComponentLoaderCustomEvents } from "@fxp/fxpsharedcomponentloader";
import { LogPropertyBag } from "../telemetry/LogPropertyBag";

//import { AngularDirective } from "../../decorators";
declare type FeatureFlagService = any;

/**
 * A main controller for FxpApp module. This is the controller having basic models and events.
 * @class Fxp.Controllers.AppController
 * @classdesc A main controller of FxpApp module
 * @example <caption>
 *  //To Use AppController
 *  angular.module('FxPApp').controller('AppController', ['AnyDependency', AppController]);
 *  function AppController(AnyDependency){ AnyDependency.doSomething(); }
 */
//@AngularDirective("appcontroller", AppController,  "FxpApp", ['$rootScope', '$scope', '$location', '$state', 'FxpUIData', 'FxpLoggerService', 'UserProfileService', 'adalAuthenticationService', 'PageLoaderService', 'AppControllerHelper', 'DeviceFactory', 'FxpConfigurationService', 'FxpBreadcrumbService', 'FxpMessageService', 'FxpBotService', '$window', 'FeatureFlagService', 'StartUpFlightConfig', 'PlannedDownTimeService', 'SessionTimeoutModalFactory', 'PageTourEventService'])
export class AppController {
  private $rootScope: IRootScope;
  private $scope: IAppControllerScope;
  private $state: IStateService;
  private $location: angular.ILocationService;
  private fxpUIData: FxpUIData;
  private fxpLogger: ILogger;
  private fxpConstants: FxpConstants;
  private userProfileService: UserProfileService;
  private appControllerHelper: AppControllerHelper;
  private fxpConfigurationService: FxpConfigurationService;
  private fxpBreadcrumbService: FxpBreadcrumbService;
  private fxpMessage: FxpMessageService;
  private sessionTimeOutModalFactory: SessionTimeoutModalFactory;
  private device: any;
  private pageLoadThreshold: any;
  private stateSuccessTime: any;
  private $onloginSuccessSubcription: any;
  private enableFxpBotWindow: boolean = true;
  private isFeedbackDialogOpen: boolean = false;

  private readonly sourceForTelemetry = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.AppController`;

  constructor(
    $rootScope: IRootScope,
    $scope: IAppControllerScope,
    $location: angular.ILocationService,
    $state: IStateService,
    fxpUIData: FxpUIData,
    fxpLoggerService: ILogger,
    userProfileService: UserProfileService,
    appControllerHelper: AppControllerHelper,
    deviceFactory: any,
    fxpConfigurationService: FxpConfigurationService,
    fxpBreadcrumbService: FxpBreadcrumbService,
    fxpMessage: FxpMessageService,
    fxpBotService: FxpBotService,
    $window: angular.IWindowService,
    private featureFlagService: FeatureFlagService,
    private startUpFlightConfig: any,
    private plannedDownTimeService: PlannedDownTimeService,
    private sessionTimeoutModalFactory: SessionTimeoutModalFactory,
    private pageTourEventService: pageTourEventService,
    private fxpStateTransitionService: FxpStateTransitionService,
    private oboUserService: OBOUserService,
    private userInfoService: UserInfoService,
    private fxpSignalRService: FxpSignalRService,
    private fxpGlobalStoreService: FxpGlobalStoreService
  ) {
    const source_telemetry = `${this.sourceForTelemetry}.Constructor`;
    this.$rootScope = $rootScope;
    this.$rootScope.showLoader = true;
    this.$rootScope.isHelpOpen = false;
    this.$rootScope.IsModelOpen = false;
    this.$scope = $scope;
    this.$state = $state;
    this.device = deviceFactory;
    this.$location = $location;
    this.fxpUIData = fxpUIData;
    this.fxpLogger = fxpLoggerService;
    this.fxpConstants = FxpConstants;
    this.userProfileService = userProfileService;
    this.fxpBreadcrumbService = fxpBreadcrumbService;
    this.appControllerHelper = appControllerHelper;
    this.fxpMessage = fxpMessage;
    this.pageLoadThreshold = parseFloat(
      fxpConfigurationService.FxpAppSettings.PageLoadDurationThreshold
    );
    this.sessionTimeOutModalFactory = sessionTimeoutModalFactory;
    this.pageTourEventService = pageTourEventService;
    this.$onloginSuccessSubcription = null;
    this.$rootScope.isBotEnabled =
      fxpConfigurationService.FxpAppSettings.IsBotEnabled;
    this.fxpConfigurationService = fxpConfigurationService;
    this.$scope.profileFlyoutConfig = 
    CommonUtils.isNullOrEmpty(fxpConfigurationService?.FxpAppSettings?.ProfileFlyoutConfig)
    ? JSON.parse(ApplicationConstants?.ProfileFlyoutConfig)
    : JSON.parse(fxpConfigurationService?.FxpAppSettings?.ProfileFlyoutConfig);

    var self = this;

    //ENUM to represent the last route transition status.
    var pageTransitionState = {
      stateNotFound: "stateNotFound",
      stateChangeError: "stateChangeError",
      stateChangeSuccess: "stateChangeSuccess",
      viewContentLoading: "viewContentLoading",
      viewContentLoaded: "viewContentLoaded",
    };
    $rootScope.$on(FxpBroadcastedEvents.ShowPopupOnPageDirty, function (event, flag) {
			window._showPageDirtyPopup = flag;
		});
    $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
      //Show popup to user if page is dirty.
      self.showPageDirtyPopupToUser(event);
		});
    $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
			if(window._showPageDirtyPopup){
        window._showPageDirtyPopup = false;
			}
		});
    $rootScope.getUrlForDashboard = this.getUrlForDashboard.bind(this);
    //Holds the last route load metrics and details
    $rootScope.$on("resetPageLoadMetrics", function (evt) {
      $rootScope.pageLoadMetrics = {
        sourceRoute: "",
        destinationRoute: "", //Holds the route name.
        pageTransitionStatus: "", //holds the page transition end state from the pageTransitionState enumeration.
        stateChangeDuration: 0, //Duration taken within state change events.
        viewLoadDuration: 0, //Duration taken within view content loading events.
        preViewLoadingDuration: 0,
        pageLoadError: "",
        pageLoadDuration: 0,
        totalDuration: 0,
        preStateDuration: 0,
        pageDisplayName: "",
        startTime: 0,
        threshold: {
          thresholdCrossed: false,
          thresholdValue: 0,
        },
      };
    });

    $rootScope.$on(
      FxpBroadcastedEvents.OnPageLoadComplete,
      function (event, pageName) {
        self.OnPageLoadCompleteEventHandler(event, pageName);
      }
    );
    // Intialization of scopes and rootscopes

    this.$scope.fxpheaderdata = {
      ElementType: "span",
      DisplayText: "",
      Name: "",
      Email: "",
      Role: "",
    };

    // custom scroll config
    this.$scope.leftNavConfig = {
      theme: "dark",
      axis: "y",
      scrollButtons: {
        enable: false,
      },
      keyboard: { scrollAmount: 5 },
    };
    this.$rootScope.isFullScreen = false;
    // it contains ui string of view full profile text
    this.$scope.ViewFullProfile =
      this.$rootScope.fxpUIConstants.UIStrings.ViewFullProfile;
    this.$scope.renderHeaderForClick = this.renderHeaderForClick.bind(this);
    this.$scope.renderHeaderForKeydown = this.renderHeaderForKeydown.bind(this);
    this.$scope.renderHeaderForFocusout =
      this.renderHeaderForFocusout.bind(this);
    this.$scope.renderHeaderMenuForKeydown =
      this.renderHeaderMenuForKeydown.bind(this);
    this.$scope.renderSideBarForKeydown =
      this.renderSideBarForKeydown.bind(this);
    this.$scope.onMessageKeyDown = this.onMessageKeyDown.bind(this);
    this.$scope.OnNavigationClick = this.onNavigationClick.bind(this);
    this.$scope.OnNavigationClickWithParams =
      this.onNavigationClickWithParams.bind(this);
    this.$scope.getPageLoadPropertyBag = this.getPageLoadPropertyBag.bind(this);
    this.$scope.logMiniProfileTelemetryInfo =
      this.logMiniProfileTelemetryInfo.bind(this);
    this.$scope.initializeFeedback = this.initializeFeedback.bind(this);
    this.$scope.getApplicationNameForDisplay =
      this.getApplicationNameForDisplay.bind(this);
    this.$scope.showViewFullProfileLink =
      window["tenantConfiguration"].ShowFullProfile;
    this.$scope.viewProfileUrl = window["tenantConfiguration"].ViewProfileUrl;
    this.$scope.copyToClipboard = this.copyToClipboard.bind(this);
    this.$rootScope.$on(CustomEvents.OnProfileFromGraphAPI, function () {
      self.$scope.showViewFullProfileLink = false;
    });

    this.$rootScope.$on(
      "leftNavHighlighted",
      this.onLeftNavHighlighted.bind(this)
    );
    this.$rootScope.$on(
      CustomEvents.StartUpFlagRetrieved,
      this.initialFlagsResponseHandler.bind(this)
    );
    this.$rootScope.$on(
      CustomEvents.PageTourFlagRetrieved,
      this.pageTourResponseHandler.bind(this)
    );
    this.$scope.$on("destroy", this.unregisterEvents);

    this.$scope.$on(
      FxpBroadcastedEvents.OnAppHeaderChanged,
      function (event, headerText) {
        self.$scope.fxpheaderdata.DisplayText = headerText;
      }
    );

    this.$scope.$on(
      FxpBroadcastedEvents.OnPageTitleChanged,
      function (event, pageTitle) {
        self.$scope.pageTitle = pageTitle;
      }
    );

    this.fxpStateTransitionService.onStateNotFound((item) => {
      console.log(
        "Fired UI-Router StateNotFound event. UnfoundState: " +
          item.toState._identifier.name +
          " from state :" +
          item.fromState._identifier.name
      );
      $rootScope.pageLoadMetrics.pageTransitionStatus =
        pageTransitionState.stateNotFound;
      var stateDuration = performance.now();
      var finalDuration = stateDuration - $rootScope.startTime;
      $rootScope.pageLoadMetrics.stateChangeDuration = finalDuration;
      let message = `The State (${item.toState._identifier.name}) you were navigating to was not found.`;
      $rootScope.pageLoadMetrics.pageLoadError = message;
      var propBag = self.getPageLoadPropertyBag($rootScope.pageLoadMetrics);
      self.fxpLogger.logError(
        source_telemetry,
        message,
        ErrorCodes.State_Not_Found,
        null,
        propBag,
        null,
        null,
        ErrorSeverityLevel.High
      );
      $rootScope.startTime = 0;
    });

    let startTime = performance.now();

    this.fxpStateTransitionService.onStateChangeFailure((item) => {
      self.logStateChange(startTime, item, "onStateChangeFailure");
      if (item.fromState.name && item.error.type == RejectType.ERROR) {
        $rootScope.pageLoadMetrics.pageTransitionStatus =
          pageTransitionState.stateChangeError;
        var msg;
        var stateDuration = performance.now();
        var finalDuration = stateDuration - $rootScope.startTime;
        $rootScope.pageLoadMetrics.stateChangeDuration = finalDuration;
        msg =
          self.$rootScope.fxpUIConstants.UIMessages.StateChangeErrorException.ErrorMessageTitle.replace(
            "{0}",
            item.toState.name
          );
        $rootScope.pageLoadMetrics.pageLoadError = msg;

        self.fxpMessage.addMessage(msg, FxpConstants.messageType.error);

        var propBag = self.getPageLoadPropertyBag($rootScope.pageLoadMetrics);
        var errorDetails = "";
        try {
          errorDetails = JSON.stringify(item.error);
        } catch (e) {
          errorDetails =
            "From State: " +
            item.fromState.name +
            " To State: " +
            item.toState.name +
            " Description: ";
          if (item.error) {
            errorDetails = errorDetails + item.error.message;
          }
        }
        self.fxpLogger.logError(
          source_telemetry,
          msg,
          ErrorCodes.State_Change_Failure,
          errorDetails,
          propBag,
          null,
          null,
          ErrorSeverityLevel.High
        );
        self.fxpLogger.setPageLoadMetrics($rootScope.pageLoadMetrics);
        $rootScope.startTime = 0;
      }
    });

    this.$scope.$on("$viewContentLoaded", function (event) {
      console.log("Fired UI-Router $viewContentLoaded event.");
      var viewContentDuration = performance.now();
      $rootScope.pageLoadMetrics.pageTransitionStatus =
        pageTransitionState.viewContentLoaded;
      self.fxpBreadcrumbService.logBreadcrumbTelemetryInfo(
        FxpConstants.BreadcrumbEventType.BreadcrumbLoad,
        null
      );

      if (!CommonUtils.isNullOrEmpty($rootScope.pageLoadMetrics.sourceRoute)) {
        if ($rootScope.pageLoadMetrics.startTime == 0) {
          $rootScope.pageLoadMetrics.startTime = self.getStartTime();
        }

        var totalDuration =
          viewContentDuration - $rootScope.pageLoadMetrics.startTime;
        self.checkIfThresholdCrossed(totalDuration);
      }

      self.fxpLogger.setPageLoadMetrics($rootScope.pageLoadMetrics);
      $rootScope.startTime = 0;
    });

    this.fxpStateTransitionService.onStateChangeSuccess((item) => {
      self.logStateChange(startTime, item, "onStateChangeSuccess");
      self.stateSuccessTime = performance.now();
      var finalDuration =
        self.stateSuccessTime - $rootScope.stateChangeStartTime;
      // Setting app header.
      self.fxpUIData.setAppHeaderFromRoute(item.toState);
      // Setting pagetitle.
      self.fxpUIData.setPageTitleFromRoute(item.toState);
      //logging from here
      $rootScope.pageLoadMetrics.pageTransitionStatus =
        pageTransitionState.stateChangeSuccess;
      $rootScope.pageLoadMetrics.stateChangeDuration = finalDuration;
      self.fxpLogger.setPageLoadMetrics($rootScope.pageLoadMetrics);

      $rootScope.isFullScreenEnabled = item.toParams
        ? item.toParams.fullScreenEnabled
        : false;
      if (!$rootScope.isFullScreenEnabled) $rootScope.isFullScreen = false;
      if (CommonUtils.isNullOrEmpty(self.$rootScope.initialFlags)) {
        let flightHandler = function () {
          self.renderBreadcrumb(item.toState);
          flightHandlerCleanUp();
        };
        let flightHandlerCleanUp = self.$rootScope.$on(
          CustomEvents.StartUpFlagRetrieved,
          flightHandler
        );
      }
      self.renderBreadcrumb(item.toState);
    });

    this.fxpStateTransitionService.onStateChangeStart((item) => {
      self.logStateChange(startTime, item, "onStateChangeStart");
    });

    $rootScope.$on("$viewContentLoading", function (event, viewConfig) {
      // What's the use of this log?
      console.log("Fired $viewContentLoading event. ");
    });

    $rootScope.$on(
      "adal:failedResourceTokenAcuqisition",
      function (source, error) {
        let errorDetails = CommonUtils.objectToString(error);
        if (!errorDetails) {
          errorDetails = "Failed to acquire resource token: ";
        }
        console.log(errorDetails);
        var propbag = fxpLoggerService.createPropertyBag();
        propbag.addToBag("Type", "adal-angular");
        propbag.addToBag("Location", window.location.hash);
        let message = `Adal resource token acquisition failure with error ${errorDetails}`;
        self.fxpLogger.logError(
          source_telemetry,
          message,
          ErrorCodes.Msal_ResourceTokenAcuqisition_Failure,
          CommonUtils.objectToString(source),
          propbag,
          null,
          null,
          ErrorSeverityLevel.Medium
        );
      }
    );

    this.$scope.userThumbnailPhoto = "/assets/pictures/User.png";
    this.$scope.reportsToThumbnailPhoto = "/assets/pictures/User.png";
    this.$rootScope.getLandingPage = this.getLandingPage.bind(this);
    this.$rootScope.navigateToLandingPage =
      this.navigateToLandingPage.bind(this);

    if (!this.$rootScope.isAuthenticated) {
      //this.$rootScope.$on('adal:loginSuccess', onLoginSuccess);
      this.$onloginSuccessSubcription = this.$rootScope.$on(
        "fxp:loginSuccess",
        onLoginSuccess
      );
    } else {
      console.log(
        "appCntrl: Already logged in. Will continue to load the application. "
      );
      onLoginSuccess();
    }

    function onLoginSuccess() {
      if (self.$onloginSuccessSubcription) {
        self.$onloginSuccessSubcription();
      }

      let currentProcessName =
        TelemetryConstants.FXP_TELEMETRY_PLATFORM_BOOTSTRAP +
        ".AuthenticationProcess.PostLoginSucessBegin";
      let eventData = new SystemEvent(
        currentProcessName,
        ComponentType.DataStore,
        "Loading FxP post login"
      );
      self.fxpLogger.logSystemEvent(source_telemetry, eventData);
      self.$rootScope.appBooted = true;
      self.fxpGlobalStoreService.DispatchGlobalAction(
        "Platform",
        ShowLoader({
          loadingText:
            $rootScope.fxpUIConstants.UIStrings.LoadingStrings.LoadingProfile,
          timeoutCallback: appControllerHelper.handleAdalErrorsLoadingProfile,
        })
      );
      self.appControllerHelper.postLoginSuccess();

      self.oboUserService.initializeOBOEntityFromContext().then(function () {
        // check this call with other getBasicProfile
        self.appControllerHelper.getBasicProfile($scope);
        var currentUserClaims = self.userInfoService.getCurrentUserClaims();
        var currentProfile = self.userInfoService.getCurrentUserProfile();
        if (
          CommonUtils.isNullOrEmpty(currentUserClaims) &&
          CommonUtils.isNullOrEmpty(currentProfile)
        ) {
          self.oboUserService.setCurrentUserStateFromLoggedInUserState();
        }
      });

      currentProcessName =
        TelemetryConstants.FXP_TELEMETRY_PLATFORM_BOOTSTRAP +
        ".AuthenticationProcess.PostLoginSucessEnd";
      eventData = new SystemEvent(
        currentProcessName,
        ComponentType.DataStore,
        ".AuthenticationProcess.Post Login steps completed"
      );

      self.fxpLogger.logSystemEvent(source_telemetry, eventData);
    }

    //Handle skype bot link
    $rootScope.$on(CustomEvents.SkypeBotInit, function (e, url, target) {
      fxpBotService.setUserContext().then(
        function (response) {
          if (self.enableFxpBotWindow) {
            $window.open(url, target);
          }
        },
        function (error) {
          self.fxpMessage.addMessage(
            $rootScope.fxpUIConstants.UIMessages.FxpBotSetContextFailedError
              .ErrorMessage,
            FxpConstants.messageType.error
          );
          //Handling data undefined
          error.data = error.data || "";
          //TODO replace error code
          let message = `Skype bot service failure with error ${error.data}`;
          self.fxpLogger.logError(
            source_telemetry,
            message,
            ErrorCodes.SkypeBotInit_Failure,
            null,
            null,
            null,
            null,
            ErrorSeverityLevel.Medium
          );
        }
      );
    });

    this.subscribeSharedComponentEvents();
    this.loadGraphToolkit();
  }
  showPageDirtyPopupToUser(event: any){
    var self = this;
    if(window._showPageDirtyPopup){
      if(!confirm(self.$rootScope.fxpUIConstants.UIMessages.PageDirtyMessages.UnsavedChangesMessage)){
        event.preventDefault();
      }
      else{
        window._showPageDirtyPopup = false;
      }
    }
  }
  subscribeSharedComponentEvents() {
    window.addEventListener(
      SharedComponentLoaderCustomEvents.SharedComponentLoadComplete,
      this.onSharedComponentLoadComplete.bind(this)
    );
    window.addEventListener(
      SharedComponentLoaderCustomEvents.SharedComponentLoaderUpdated,
      this.onSharedComponentLoaderUpdated.bind(this)
    );
    window.addEventListener(
      SharedComponentLoaderCustomEvents.SharedComponentCallBackInvoked,
      this.onSharedComponentCallBackInvoked.bind(this)
    );
    window.addEventListener(
      SharedComponentLoaderCustomEvents.SharedComponentOnDestroyInvoked,
      this.onSharedComponentOnDestroyInvoked.bind(this)
    );
  }

  private get SharedComponentFeatureName(): string {
    return `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.SharedComponent`;
  }

  onSharedComponentLoadComplete(eventArgs: any) {
    this.logSharedComponentFeatureUsage(
      "SharedComponentLoadComplete",
      eventArgs.detail
    );
  }

  onSharedComponentLoaderUpdated(eventArgs: any) {
    this.logSharedComponentFeatureUsage(
      "SharedComponentUpdated",
      eventArgs.detail
    );
  }

  onSharedComponentCallBackInvoked(eventArgs: any) {
    this.logSharedComponentFeatureUsage(
      "SharedComponentCallBackInvoked",
      eventArgs.detail
    );
  }

  onSharedComponentOnDestroyInvoked(eventArgs: any) {
    this.logSharedComponentFeatureUsage(
      "SharedComponentUnloaded",
      eventArgs.detail
    );
  }

  logSharedComponentFeatureUsage(actionName: string, eventPayload: any) {
    const source_telemetry = `${this.sourceForTelemetry}.onSharedComponentLoadComplete`;
    const sharedComponentFeature = new FeatureUsageEvent(
      this.SharedComponentFeatureName,
      ActionType.System,
      actionName,
      EventName.PageLoad,
      ComponentType.Web
    );
    const props = this.fxpLogger.createPropertyBag();
    if (eventPayload && CommonUtils.isObject(eventPayload)) {
      const payload = eventPayload;
      Object.keys(payload).forEach((property) => {
        props.addToBag(property, payload[property]);
      });
    }
    this.fxpLogger.logFeatureUsageEvent(
      source_telemetry,
      sharedComponentFeature,
      props
    );
  }

  getUrlForDashboard() {
    const isIframe = sessionStorage["isIFrame"];
    if (isIframe) {
      return `/Home/Dashboard/${this.$rootScope.defaultAppRole}?isIframe=${isIframe}`;
    }
    return `/Home/Dashboard/${this.$rootScope.defaultAppRole}`;
  }

  private logStateChange(
    startTime: number,
    item: any,
    moduleName: string
  ): void {
    const source_telemetry = `${this.sourceForTelemetry}.LogStateChange`;
    let timeTaken = performance.now() - startTime;
    let currentTime = new Date().toLocaleString();
    let propbag = this.fxpLogger.createPropertyBag();
    propbag.addToBag(`${moduleName} current time - `, `${currentTime}`);
    propbag.addToBag(`${moduleName} time taken - `, `${timeTaken} ms`);
    propbag.addToBag(`FromState-Url`, item.fromState.url);
    propbag.addToBag(`ToState-Url`, item.toState.url);
    propbag.addToBag("FromState", item.fromState.name);
    propbag.addToBag("ToState", item.toState.name);
    if (item.error) {
      propbag.addToBag("ErrorType", item.error.type);
    }

    let currentProcessName =
      TelemetryConstants.FXP_TELEMETRY_BASE_NAME + ".StateTransition";
    let eventData = new SystemEvent(
      currentProcessName,
      ComponentType.DataStore,
      `State changed to ${item.toState.name} from ${item.fromState.name}.`
    );

    this.fxpLogger.logSystemEvent(source_telemetry, eventData, propbag);
  }

  private OnPageLoadCompleteEventHandler(event, pageName) {
    const endTime = performance.now();
    const source_telemetry = `${this.sourceForTelemetry}.OnPageLoadCompleteEventHandler`;
    let startTime = 0;
    let isFxPBoostrapTimeIncluded = false;

    if (sessionStorage["LoadStartedTime"]) {
      startTime = parseFloat(sessionStorage["LoadStartedTime"]);
      sessionStorage.removeItem("LoadStartedTime");
      isFxPBoostrapTimeIncluded = true;
    } else {
      startTime = parseFloat(this.$rootScope.stateChangeStartTime);
      if (isNaN(startTime) || startTime == 0) {
        return;
      }
      this.$rootScope.stateChangeStartTime = 0;
      let startTimeProps = this.fxpLogger.createPropertyBag();
      startTimeProps.addToBag("StateChangeStartTime", startTime.toString());
      startTimeProps.addToBag("PageName", pageName);
      startTimeProps.addToBag("CurrentState", this.$state.current.name);
      this.fxpLogger.logEvent(
        source_telemetry,
        `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.PageLoad.Completed`,
        startTimeProps
      );
    }

    const pageLoadDuration = parseFloat(
      ((endTime - startTime) / 1000).toFixed(1)
    );
    const isActingOnBehalfOf = this.userInfoService.isActingOnBehalfOf();
    const properties = this.fxpLogger.createPropertyBag();

    properties.addToBag("LoadDuration", pageLoadDuration.toString());
    properties.addToBag("IsOBOMode", isActingOnBehalfOf.toString());
    properties.addToBag("PageName", pageName);
    properties.addToBag("Unit", "seconds");
    properties.addToBag(
      "IsFxPBoostrapTimeIncluded",
      isFxPBoostrapTimeIncluded.toString()
    );
    if (sessionStorage["AppCacheReset"]) {
      properties.addToBag("AppCacheReset", "true");
      console.log("AppCacheReset - true");
      sessionStorage.removeItem("AppCacheReset");
    }
    const eventName = pageName + "-LoadComplete";
    this.fxpLogger.logMetric(
      source_telemetry,
      eventName,
      pageLoadDuration,
      properties
    );
    sessionStorage[eventName] = pageLoadDuration.toString();
    console.log(eventName + " - " + pageLoadDuration);
  }

  /**
   *an internal method to populate propertyBag values.
   */
  getPageLoadPropertyBag = function (pageLoadData: any) {
    const self = this;
    const propBag = self.fxpLogger.createPropertyBag();
    propBag.addToBag("sourceRoute", pageLoadData.sourceRoute);
    propBag.addToBag("pageDisplayName", pageLoadData.pageDisplayName);
    propBag.addToBag("destinationRoute", pageLoadData.destinationRoute);
    propBag.addToBag("pageTransitionStatus", pageLoadData.pageTransitionStatus);
    propBag.addToBag(
      "stateChangeDuration",
      pageLoadData.stateChangeDuration.toString()
    );
    propBag.addToBag("error", pageLoadData.pageLoadError);
    propBag.addToBag(
      "partnerPageLoadDuration",
      pageLoadData.pageLoadDuration.toString()
    );
    propBag.addToBag("totalDuration", pageLoadData.totalDuration.toString());
    propBag.addToBag(
      "preStateDuration",
      pageLoadData.preStateDuration.toString()
    );
    propBag.addToBag(
      "thresholdCrossed",
      pageLoadData.threshold.thresholdCrossed.toString()
    );
    propBag.addToBag(
      "thresholdValue",
      pageLoadData.threshold.thresholdValue.toString()
    );
    return propBag;
  };
  /**
   *an internal method to check EnableBreadcrumb status on success calls breadcrumbservice
   */
  renderBreadcrumb = function (toState) {
    const self = this;
    if (
      self.$rootScope.initialFlags &&
      self.$rootScope.initialFlags.breadcrumbEnabled
    ) {
      self.fxpBreadcrumbService.setBreadcrumbFromRoute(toState);
    }
  };
  /**
   * An event handler whenever header is clicked.
   * @method Fxp.Controllers.AppController.renderHeaderForClick
   * @param {Event} $event An event object which is passed from the view.
   * @example <caption> Example to use renderHeaderForClick</caption>
   *  <div ng-app="AppController"><div ng-click="renderHeaderForClick">Render header</div></div>;
   *  <div ng-app="AppController as app"><div ng-click="app.renderHeaderForClick">Render header</div></div>;
   */
  renderHeaderForClick = function ($event) {
    $(".helpflyoutmenu ul.dropdown-menu").animate({ scrollTop: 0 }, "fast");
    var featureFlagJson = localStorage["FeatureFlags"];
    var featureflags = {};
    var isDefaultLinkEnabled = true;
    if (featureFlagJson) {
      featureflags = JSON.parse(featureFlagJson);
      for(let i=0; i<this.$scope.profileFlyoutConfig.length; i++){
        const experience = this.$scope.profileFlyoutConfig[i];
        const featureFlag = experience.featureFlag;
        experience.link = experience.link.replace("{userAlias}", this.$rootScope?.userProfile?.alias?.toLowerCase());
        this.$scope.profileFlyoutConfig[i].link = experience.link.replace("{userId}", this.$rootScope?.userProfile?.aadObjectId);
        if (featureFlag) {
          this.$scope.profileFlyoutConfig[i].isEnabled = featureflags[featureFlag] == undefined ? false: featureflags[featureFlag];
          if(experience.experience == "r360wbExperience"){
            this.$scope.manageProfileFlight = this.$scope.profileFlyoutConfig[i].isEnabled;
          }
          if(this.$scope.profileFlyoutConfig[i].isEnabled){
            isDefaultLinkEnabled = false;
            break;
          }
        }
      }
      if(isDefaultLinkEnabled){
        this.$scope.profileFlyoutConfig[this.$scope.profileFlyoutConfig.length - 1].isEnabled = true;
      }
    } else {
      const experience = this.$scope.profileFlyoutConfig[this.$scope.profileFlyoutConfig.length - 1];
      experience.link = experience.link.replace("{userAlias}", this.$rootScope?.userProfile?.alias?.toLowerCase());
      this.$scope.profileFlyoutConfig[this.$scope.profileFlyoutConfig.length - 1].link = experience.link.replace("{userId}", this.$rootScope?.userProfile?.aadObjectId);
      this.$scope.profileFlyoutConfig[this.$scope.profileFlyoutConfig.length - 1].isEnabled = true;
    }
    var target = $($event.target);

    if (target.parent().hasClass("open")) {
      target.parent().removeClass("open");
      target.attr("aria-expanded", "false");
    } else {
      target.parent().addClass("open");
      target.attr("aria-expanded", "true");
    }

    if ($event.screenX != 0 && $event.screenY != 0)
      target.addClass("focus-removed");
  };
   /**
   * Function to add/remove classes and aria-attributes after click.
   * @method Fxp.Controllers.AppController.renderNotificationButtonForClick
   * @param {Event} $event An event object which is passed from the view.
   */

  renderNotificationButtonForClick = function ($event) {
    var target = $($event.target);
    var buttonDom = target.closest("button");
    if (buttonDom.parent().hasClass("open")) {
      buttonDom.parent().removeClass("open");
      buttonDom.attr("aria-expanded", "false");
    } else {
      buttonDom.parent().addClass("open");
      buttonDom.attr("aria-expanded", "true");
    }
  };
  /**
   * An event handler whenever header is clicked.
   * @method Fxp.Controllers.AppController.renderHeaderForKeydown
   * @param {Event} $event An event object which is passed from the view.
   * @example <caption> Example to use renderHeaderForKeydown</caption>
   *  <div ng-app="AppController"><div ng-keydown="renderHeaderForKeydown">Render header</div></div>;
   *  <div ng-app="AppController as app"><div ng-keydown="app.renderHeaderForKeydown">Render header</div></div>;
   */
  renderHeaderForKeydown = function ($event) {
    var target = $($event.target);
    if ($event.key == "Tab" && $event.shiftKey) {
      target.parent().removeClass("open");
      target.attr("aria-expanded", "false");
    } else if ($event.key == "Down" && target.parent().hasClass("open")) {
      var menuItems = target.parent().find("[uib-dropdown-menu] li a");
      menuItems[0].focus();
    }
  };

  /**
   * An event handler whenever focus is lost from header.
   * @method Fxp.Controllers.AppController.renderHeaderForFocusout
   * @param {Event} $event An event object which is passed from the view.
   * @example <caption> Example to use renderHeaderForFocusout</caption>
   *  <div ng-app="AppController"><div ng-blur="renderHeaderForFocusout">Focus out header</div></div>;
   *  <div ng-app="AppController as app"><div ng-blur="app.renderHeaderForFocusout">Focus out header</div></div>;
   */
  renderHeaderForFocusout = function ($event) {
    var target = $($event.target);
    target.removeClass("focus-removed");
  };

  /**
   * An event handler whenever a key is pressed on header menu.
   * @method Fxp.Controllers.AppController.renderHeaderMenuForKeydown
   * @param {Event} $event An event object which is passed from the view.
   * @example <caption> Example to use renderHeaderMenuForKeydown</caption>
   *  <div ng-app="AppController"><div ng-keydown="renderHeaderMenuForKeydown">Render header menu</div></div>;
   *  <div ng-app="AppController as app"><div ng-keydown="app.renderHeaderMenuForKeydown">Render header menu</div></div>;
   */
  renderHeaderMenuForKeydown = function ($event) {
    var targetMenu = $($event.target).closest("[uib-dropdown-menu]"),
      targetMenuToggleBtnId = targetMenu.attr("aria-labelledby"),
      targetMenuToggleBtn = $("#" + targetMenuToggleBtnId),
      allMenuItems = targetMenu.find("li a"),
      currentMenuItemIndex = allMenuItems.index($event.target);

    if ($event.keyCode == this.fxpConstants.keyCodes.escapeKey) {
      targetMenuToggleBtn.focus();
      if (targetMenuToggleBtnId.indexOf("Fxpdashboard_LeftNavItem") != -1) {
        targetMenu.parent().removeClass("open");
        targetMenuToggleBtn.attr("aria-expanded", "false");
      }
    } else if (
      $event.keyCode == this.fxpConstants.keyCodes.tabKey &&
      !$event.shiftKey &&
      currentMenuItemIndex == allMenuItems.length - 1
    ) {
      targetMenuToggleBtn.focus();
      targetMenu.parent().removeClass("open");
      targetMenuToggleBtn.attr("aria-expanded", "false");
    } else if (
      $event.keyCode == this.fxpConstants.keyCodes.tabKey &&
      $event.shiftKey &&
      currentMenuItemIndex == 0
    ) {
      $event.preventDefault();
      $event.stopPropagation();
      targetMenuToggleBtn.focus();
    } else if ($event.keyCode == this.fxpConstants.keyCodes.arrowDownKey) {
      $event.preventDefault();
      $event.stopPropagation();
      if (currentMenuItemIndex < allMenuItems.length - 1) {
        allMenuItems[currentMenuItemIndex + 1].focus();
      } else {
        allMenuItems[0].focus();
      }
    } else if ($event.keyCode == this.fxpConstants.keyCodes.arrowUpKey) {
      $event.preventDefault();
      $event.stopPropagation();
      if (currentMenuItemIndex > 0) {
        allMenuItems[currentMenuItemIndex - 1].focus();
      } else {
        allMenuItems[allMenuItems.length - 1].focus();
      }
    }
  };

  /**
   * An event handler whenever header is clicked.
   * @method Fxp.Controllers.AppController.renderHeaderForKeydown
   * @param {Event} $event An event object which is passed from the view.
   * @example <caption> Example to use renderHeaderForKeydown</caption>
   *  <div ng-app="AppController"><div ng-keydown="renderHeaderForKeydown">Render header</div></div>;
   *  <div ng-app="AppController as app"><div ng-keydown="app.renderHeaderForKeydown">Render header</div></div>;
   */
  renderSideBarForKeydown = function ($event) {
    var target = $($event.target),
      currentIndex = $(".left-nav-menu").index(target.parent()),
      previousItem = currentIndex
        ? $(".left-nav-menu")[currentIndex - 1]
        : null,
      previousItemId = previousItem ? $(previousItem).find("a")[0].id : "";
    var menuItems: any;
    if (
      ($event.key == "Down" || ($event.key == "Tab" && !$event.shiftKey)) &&
      target.parent().hasClass("open")
    ) {
      menuItems = document.querySelectorAll(
        "[aria-labelledby=" + target[0].id + "] li a"
      );
      $event.preventDefault();
      $event.stopPropagation();
      menuItems[0].focus();
    } else if (
      $event.key == "Tab" &&
      $event.shiftKey &&
      previousItem &&
      $(previousItem).hasClass("open")
    ) {
      menuItems = document.querySelectorAll(
        "[aria-labelledby=" + previousItemId + "] li a"
      );
      $event.preventDefault();
      $event.stopPropagation();
      menuItems[menuItems.length - 1].focus();
    }
  };
  /**
   * An event handler whenever a key is pressed on Message.
   * @method Fxp.Controllers.AppController.onMessageKeyDown
   * @param {Event} $event An event object which is passed from the view.
   * @example <caption> Example to use onMessageKeyDown</caption>
   *  <div ng-app="AppController"><div ng-keydown="onMessageKeyDown">Fxp Message</div></div>;
   *  <div ng-app="AppController as app"><div ng-keydown="app.onMessageKeyDown">Focus out</div></div>;
   */
  onMessageKeyDown = function ($event) {
    if ($event.key == "Tab") {
      var targetMessage = $($event.target).closest(".message"),
        allMessages = $(".message"),
        currentMessageIndex = allMessages.index(targetMessage),
        targetType = $event.target.tagName.toLowerCase(),
        isFirstMessageFocused = currentMessageIndex == 0 && targetType == "div",
        isLastMessageFocused =
          currentMessageIndex == allMessages.length - 1 &&
          targetType == "button",
        isForwardNavigation = !$event.shiftKey,
        isBackwardNavigation = $event.shiftKey;
      if (isForwardNavigation && isLastMessageFocused) {
        $event.preventDefault();
        $event.stopPropagation();
        $(allMessages[0]).find(".message-content").focus();
      } else if (isBackwardNavigation && isFirstMessageFocused) {
        $event.preventDefault();
        $event.stopPropagation();
        $(allMessages[allMessages.length - 1])
          .find("button")
          .focus();
      }
    }
  };

  /**
   * A click handler when a navigation button is clicked.
   * @method Fxp.Controllers.AppController.onNavigationClick
   * @param {object} menuItem An object which is passed from the view.
   * @example <caption> Example to use onNavigationClick</caption>
   *  <div ng-app="AppController"><div fxp-click="onNavigationClick(item)">Navigation Button</div></div>;
   *  <div ng-app="AppController as app"><div fxp-click="app.onNavigationClick(item)">Navigation Button</div></div>;
   */
  onNavigationClick = function (menuItem) {
    this.$state.go(menuItem);
  };

  /**
   * A click handler when a navigation button is clicked.
   * @method Fxp.Controllers.AppController.onNavigationClickWithParams
   * @param {object} menuItem An object which is passed from the view.
   * @example <caption> Example to use onNavigationClickWithParams</caption>
   *  <div ng-app="AppController"><div fxp-click="onNavigationClickWithParams(item, params)">Navigation Button</div></div>;
   *  <div ng-app="AppController as app"><div fxp-click="app.onNavigationClickWithParams(item, params)">Navigation Button</div></div>;
   */

  onNavigationClickWithParams = function (menuItem, params) {
    this.$state.go(
      menuItem,
      JSON.parse(JSON.stringify(eval("(" + params + ")"))) // CodeQL[SM04509] Genuine use.
    );
  };
  /**
   * An method to get the default landing page.
   * @method Fxp.Controllers.AppController.getLandingPage
   * @example <caption> Example to use getLandingPage</caption>
   *  <div ng-app="AppController"><div ng-click="getLandingPage">Get Landing Page</div></div>;
   *  <div ng-app="AppController as app"><div ng-click="app.getLandingPage">getLandingPage</div></div>;
   */
  getLandingPage = function () {
    if (this.$state.get("DashBoard")) {
      return this.$state.href("DashBoard", {}, { absolute: true });
    } else {
      return this.$state.href(
        this.$state.get()[1].name,
        {},
        { absolute: true }
      );
    }
  };

  /**
   * A method to navigate to landing page.
   * @method Fxp.Controllers.AppController.navigateToLandingPage
   * @example <caption> Example to use navigateToLandingPage</caption>
   *  <div ng-app="AppController"><div ng-click="navigateToLandingPage">Navigate to landing page</div></div>;
   *  <div ng-app="AppController as app"><div ng-click="app.navigateToLandingPage">Navigate to landing page</div></div>;
   */
  navigateToLandingPage = function () {
    var landingState = this.$state.get("Dashboard");
    if (CommonUtils.isNullOrEmpty(landingState)) {
      landingState = this.$rootScope.defaultStateName;
    }
    this.logHeaderClickTelemetryInfo(landingState);
    return this.$state.current.name === landingState
      ? this.$state.reload()
      : this.$state.go(landingState, {}, { location: "replace" });
  };

  getApplicationNameForDisplay() {
    const hasCSARoles = this.hasCSARoles();
    if (hasCSARoles) {
      return this.getAppNameForCSARoles();
    }
    return this.getAppNameForNonCSARoles();
  }

  getAppNameForCSARoles() {
    if (this.device.isTablet() || this.device.isMobile()) {
      return this.fxpConfigurationService.FxpAppSettings
        .GlobalCustomerExperienceApplicationNameForTablet;
    }
    return this.fxpConfigurationService.FxpAppSettings
      .GlobalCustomerExperienceApplicationName;
  }

  getAppNameForNonCSARoles() {
    if (this.device.isTablet() || this.device.isMobile()) {
      return this.$rootScope.fxpUIConstants.UIStrings.AppHeaderAlias;
    }
    return this.$rootScope.fxpUIConstants.UIStrings.AppHeader;
  }

  hasCSARoles(): boolean {
    const defaultAppRole =
      this.userInfoService.getCurrentUserClaims().defaultAppRole;
    const csaRoles =
      this.fxpConfigurationService.FxpAppSettings
        .AppRolesForGlobalCustomerExperienceApplicationName;
    if (
      CommonUtils.isNullOrEmpty(defaultAppRole) ||
      CommonUtils.isNullOrEmpty(csaRoles)
    )
      return false;
    return csaRoles === "*" || csaRoles.split(",").indexOf(defaultAppRole) >= 0;
  }

  onLeftNavHighlighted = function (event, item) {
    this.plannedDownTimeService.currentLeftNavItem = item;
    this.$rootScope.currentLeftNavItem = item;
    //prevent undefined issue
    this.$rootScope.initialFlags = this.$rootScope.initialFlags || {};

    if (this.$rootScope.initialFlags.flashEnabled) {
      this.plannedDownTimeService.updateFlash();
      if (this.$rootScope.isSystemAnnouncementVisible) {
        this.plannedDownTimeService.updateSystemAnnouncementFlash();
      }
    }
  };

  initialFlagsResponseHandler = function () {
    //prevent undefined issue
    let self = this;
    this.$rootScope.initialFlags = this.$rootScope.initialFlags || {};
    this.$rootScope.isSystemAnnouncementVisible = true;
    if (this.$rootScope.initialFlags.flashEnabled) {
      this.plannedDownTimeService.pollForPlannedDownTimesandUpdateFlash();
      this.plannedDownTimeService.subscribeToSignalREvent();
      this.plannedDownTimeService.GetSystemAnnouncementMessageandUpdateFlash();
      this.$rootScope.$on(
        FxpBroadcastedEvents.OnUserSessionTimeout,
        function () {
          console.warn(
            "Cancelling the poll for getdowntime method call because of session timeout."
          );
          self.plannedDownTimeService.pausePlannedDownTimesPoll();
          self.plannedDownTimeService.unsubscribeSignalREvent();

          self.fxpSignalRService.unsubscribeAll();
        }
      );
    }
    this.sessionTimeoutModalFactory.init();

    let CceGceFlagEnabled =
      this.fxpGlobalStoreService.GetPlatformState().FeatureFlags[
        this.fxpConfigurationService.FxpAppSettings.EnableCceGceUIRing1
      ];
    if (this.hasCSARoles() && CceGceFlagEnabled)
      this.$scope.showViewFullProfileLink = false;
  };

  pageTourResponseHandler = function () {
    if (this.$rootScope.initialFlags.pageTourEnabled) {
      this.pageTourEventService.init();
    }
  };

  unregisterEvents = function () {
    this.handler();
    this.flightHandler();
  };

  logHeaderClickTelemetryInfo(state: any): void {
    var self = this,
      propBag;
    propBag = self.fxpLogger.createPropertyBag();
    propBag.addToBag(
      FxpConstants.metricConstants.HeaderClickNavigatedStateName,
      state.name
    );
    propBag.addToBag(
      FxpConstants.metricConstants.HeaderClickNavigatedStateTemplateURL,
      state.templateUrl
    );
    self.fxpLogger.logEvent(
      `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.appCntrl.logHeaderClickTelemetryInfo`,
      `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.HeaderClick`,
      propBag
    );
  }
  /**
   * An event handler to log Telemetry when mini profile icon is clicked.
   * @method Fxp.Controllers.AppController.logMiniProfileTelemetryInfo
   * @param {string} action action to decide whether it is profile icon click or manage profile click.
   * @param {boolean} isModalOpen flag to decide if uib dropdown is open or closed.
   */

  logMiniProfileTelemetryInfo(action: string, isModalOpen: boolean, properties?: any): void {
    const viewProfileFeature = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.ViewProfile`;
    let viewProfileFeatureUsageEvent: FeatureUsageEvent;

    let source = `${this.sourceForTelemetry}.logMiniProfileTelemetryInfo`;
    if (isModalOpen) {
      var propBag: LogPropertyBag;
      var self = this;
      propBag = self.fxpLogger.createPropertyBag();
      for(let prop in properties){
        propBag.addToBag(prop, properties[prop]);
      }
      switch (action) {
        case "ProfileIconClick":
          self.fxpLogger.renewCorrelationId();
          propBag.addToBag(
            FxpConstants.metricConstants.MiniProfileIconClick,
            "Yes"
          );
          viewProfileFeatureUsageEvent = new FeatureUsageEvent(
            viewProfileFeature,
            ActionType.User,
            "MiniProfileIconClicked",
            EventName.ButtonClick,
            ComponentType.Web
          );
          break;
        case "ProfileLinkClick":
          self.fxpLogger.renewSubCorrelationId();
          propBag.addToBag(
            FxpConstants.metricConstants.ProfileLinkClick,
            "Yes"
          );
          viewProfileFeatureUsageEvent = new FeatureUsageEvent(
            viewProfileFeature,
            ActionType.User,
            "ProfileLinkClicked",
            EventName.LinkClicked,
            ComponentType.Web
          );
          break;
        case "ChatIconClick":
          self.fxpLogger.renewSubCorrelationId();
          propBag.addToBag(FxpConstants.metricConstants.ChatIconClick, "Yes");
          viewProfileFeatureUsageEvent = new FeatureUsageEvent(
            viewProfileFeature,
            ActionType.User,
            "ChatIconClicked",
            EventName.ButtonClick,
            ComponentType.Web
          );
          break;
        case "MailIconClick":
          self.fxpLogger.renewSubCorrelationId();
          propBag.addToBag(FxpConstants.metricConstants.MailIconClick, "Yes");
          viewProfileFeatureUsageEvent = new FeatureUsageEvent(
            viewProfileFeature,
            ActionType.User,
            "MailIconClicked",
            EventName.ButtonClick,
            ComponentType.Web
          );
      }
      self.fxpLogger.logEvent(
        source,
        `${this.sourceForTelemetry}.MiniProfileClick`,
        propBag
      );
      self.fxpLogger.logFeatureUsageEvent(
        source,
        viewProfileFeatureUsageEvent,
        propBag
      );
    }
  }

  restore() {
    this.$rootScope.isFullScreen = false;
  }

  maximize() {
    this.$rootScope.isFullScreen = true;
  }

  toggleOCV(){
    return window["fxpOcvLoaded"] && window["fxpOcvFloodGateLoaded"];
  }

  getStartTime() {
    this.$rootScope.pageLoadMetrics.stateChangeStartTime =
      this.$rootScope.stateChangeStartTime;
    this.$rootScope.pageLoadMetrics.rootscopestartTime =
      this.$rootScope.startTime;

    if (
      this.$rootScope.startTime != undefined &&
      this.$rootScope.startTime != 0
    )
      return this.$rootScope.startTime;

    return this.$rootScope.stateChangeStartTime;
  }

  checkIfThresholdCrossed(totalDuration) {
    if (
      totalDuration >= this.pageLoadThreshold * 1000 &&
      !this.$rootScope.pageLoadMetrics.threshold.thresholdCrossed
    ) {
      var msg, propBag;
      msg =
        "Threshold value of " + this.pageLoadThreshold + " second(s) crossed.";
      this.$rootScope.pageLoadMetrics.threshold.thresholdCrossed = true;
      this.$rootScope.pageLoadMetrics.threshold.thresholdValue =
        this.pageLoadThreshold;
      this.$rootScope.pageLoadMetrics.pageLoadError = msg;

      propBag = this.$scope.getPageLoadPropertyBag(
        this.$rootScope.pageLoadMetrics
      );
      propBag.addToBag("viewContentLoadDuration", totalDuration.toString());

      this.fxpLogger.logError(
        `${this.sourceForTelemetry}.CheckfThresholdCrossed`,
        msg,
        ErrorCodes.API_Threshold_Crossed,
        null,
        propBag,
        null,
        null,
        ErrorSeverityLevel.Low
      );
    }
  }

  getPartnerName(stateName: string): string {
    let stateDetails = this.$state.get(stateName);
    if (!stateDetails || !stateDetails.data) {
      return "";
    }
    if (stateDetails.data.ocvAreaName) {
      return stateDetails.data.ocvAreaName;
    }
    if (stateDetails.data.partnerTelemetryName)
      return stateDetails.data.partnerTelemetryName;

    return "";
  }

  initializeFeedback() {
    let self = this;
    const source_telemetry = `${this.sourceForTelemetry}.InitializeFeedback`;
    const currentUserData = self.userInfoService.getCurrentUserData();
    window["OfficeBrowserFeedback"] =
      window["OfficeBrowserFeedback"] || <any>{};
    window["OfficeBrowserFeedback"].initOptions = {
      appId: self.fxpConfigurationService.FxpAppSettings.OCVAppId, // Replace by your own app id
      stylesUrl: "lib/OCV/styles/officebrowserfeedback_fxp.css", // Replace by where you have hosted the .css
      intlUrl: "lib/OCV/intl/", // Replace by where you have hosted the intl files.
      // intlFilename is an optional property for using a custom filename for the internationalized strings, the default filename will be used if it is not specified
      environment: self.fxpConfigurationService.FxpAppSettings.OCVEnvironment, // 0 - Prod, 1 - Int
      primaryColour: "#0078d6", // Replace by a colour which goes with your website.
      secondaryColour: "#0078D4", // Replace by a colour which goes with your website.
      userEmailConsentDefault: false,
      userEmail: currentUserData.EmailName || currentUserData.email,
      build: self.fxpConfigurationService.FxpAppSettings.BuildNumber, // (optional) Another example: 99.1.1.123456789
      locale: "en",
      transitionEnabled: false,
      onError: function onError(err) {
        self.fxpLogger.logError(
          source_telemetry,
          "Feedback OCV SDK encountered error: " + err,
          ErrorCodes.InitializeFeedback_Failure,
          null,
          null,
          null,
          null,
          ErrorSeverityLevel.Low
        );
      }, // (optional) Callback which gets executed when SDK errors
      onDismiss: function dismissed(submitted) {
        self.isFeedbackDialogOpen = false;
        if (submitted) {
          self.fxpLogger.logEvent(
            source_telemetry,
            `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.FeedbackSubmitted`
          );
          self.fxpMessage.addMessage(
            "Your feedback has been submitted",
            "success"
          );
        } else {
          self.fxpLogger.logError(
            source_telemetry,
            "Error while submitting the feedback.",
            ErrorCodes.Error_SubmittingFeedback,
            null
          );
          self.fxpMessage.addMessage(
            "Error while submitting the feedback.",
            "error"
          );
        }
      },
      onCancel: function cancel() {
        $("#feedback-open").focus();
        self.isFeedbackDialogOpen = false;
      },
    };
    this.LaunchFeedback();
  }

  LaunchFeedback() {
    var launchOptions = {
      webGroup: {
        browser: navigator["browserDetails"]
          ? navigator["browserDetails"].name
          : "",
        browserVersion: navigator["browserDetails"]
          ? navigator["browserDetails"].version
          : "",
        sourcePageName: document.getElementsByTagName("title")[0].innerHTML,
        sourcePageURI: window.location.href,
      },
      telemetryGroup: {
        platform: navigator["deviceDetails"],
        featureArea: this.getPartnerName(this.$state.current.name),
        loggableUserId:
          this.fxpGlobalStoreService.GetPlatformState().CurrentUser.Claims
            .aadObjectId,
        tenantId: this.fxpConfigurationService.FxpAppSettings.TenantId,
      },
    };
    this.handlePromise(
      window["OfficeBrowserFeedback"].multiFeedback(launchOptions)
    );
  }

  handlePromise(promise) {
    let self = this;
    const source_telemetry = `${this.sourceForTelemetry}.HandlePromise`;
    const feedbackFeature = new FeatureUsageEvent(
      `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.OCVFeedback`,
      ActionType.User,
      "FeedbackComponentLoad",
      EventName.ButtonClick,
      ComponentType.Web
    );
    promise
      .then(function onFulfilled() {
        self.isFeedbackDialogOpen = true;
        feedbackFeature.ActionStatus = ActionStatus.Succeeded;
        self.fxpLogger.logFeatureUsageEvent(source_telemetry, feedbackFeature);
      })
      .catch(function onRejected(err) {
        self.fxpLogger.logError(
          source_telemetry,
          "Error while loading feedback UI. Error: " + err,
          ErrorCodes.Error_LoadingFeedback,
          null,
          null,
          null,
          null,
          ErrorSeverityLevel.Medium
        );
        self.isFeedbackDialogOpen = false;
        feedbackFeature.ActionStatus = ActionStatus.Failed;
        self.fxpLogger.logFeatureUsageEvent(source_telemetry, feedbackFeature);
      });
  }

  protected loadGraphToolkit() {
    try {
      SimpleProvider.name;
    } catch {}
  }

  copyToClipboard() {
    let copyText: string = "";
    for(var experience of this.$scope.profileFlyoutConfig){
      if(experience.isEnabled){
        if(experience.link.includes("engage360")){ //TODO: add to application constants
          copyText = experience.link;
          
        }
        else{
          const esxpBaseURLindex = document.baseURI.indexOf("#");
          copyText = document.baseURI.slice(0, esxpBaseURLindex) + experience.link;
        }
        break;
      }
    }
    navigator.clipboard.writeText(copyText).then(
      function () {
        document.getElementById("copytooltip").innerHTML = "Copied!";
        document.getElementById("copyicon").blur;
      },
      function () {
        document.getElementById("copytooltip").innerHTML = "Could not copy";
      }
    );
    document.getElementById("copyicon").onblur = () =>
      (document.getElementById("copytooltip").innerHTML = "Copy profile URL");
    document.getElementById("copyicon").onmouseup = () =>
      (document.getElementById("copytooltip").innerHTML = "Copy profile URL");
  }
}

AppController.$inject = [
  "$rootScope",
  "$scope",
  "$location",
  "$state",
  "FxpUIData",
  "FxpLoggerService",
  "UserProfileService",
  "AppControllerHelper",
  "DeviceFactory",
  "FxpConfigurationService",
  "FxpBreadcrumbService",
  "FxpMessageService",
  "FxpBotService",
  "$window",
  "FeatureFlagService",
  "StartUpFlightConfig",
  "PlannedDownTimeService",
  "SessionTimeoutModalFactory",
  "PageTourEventService",
  "FxpStateTransitionService",
  "OBOUserService",
  "UserInfoService",
  "FxpSignalRService",
  "FxpGlobalStoreService",
];
