interface chromeUserExperienceObject {
    noThanksFlag: number;
    timestamp: number;
    userName: string;
}

export class FxpComponentDirectives implements angular.IDirective {

    static getEmptyDirective(): angular.IDirective {
        var directive = {
            restrict: 'E',
            template: ''
        };
        return directive;
    }

    static fxpleftnavigation($templateCache): angular.IDirective {
        var directive: any = {};

        directive.restrict = 'AE';

        directive.template = $templateCache.get('leftnavigation.html')

        return directive;
    }

    static populateNotification($templateCache): angular.IDirective {
        var directive = {
            restrict: 'AE',
            template: $templateCache.get('browserExperience.html')
        };
        return directive;
    }

    static fxpheader($templateCache): angular.IDirective {
        var headerdirective: any = {};
        headerdirective.restrict = 'AE';
        headerdirective.template = $templateCache.get('fxpheader.html');

        return headerdirective;
    }

    static fxpfooter(deviceFactory, $templateCache): angular.IDirective {
        var directive: any = {};

        directive.restrict = 'AE';

        directive.template = $templateCache.get('footer.html');

        directive.link = function (scope, element, attr) {
            // Setting min-height of partner app
            element.ready(function () {
                if (deviceFactory.isMobile()) {
                    var headerHeight = angular.element("#header").height(),
                        footerHeight = angular.element("#fxpfooter").height(),
                        windowHeight = self.window.innerHeight,
                        partnerApp = angular.element(".partner-app");

                    partnerApp.css('min-height', windowHeight - headerHeight - footerHeight);
                }
            });
        }

        return directive;
    }

    static fxpOboHeader($templateCache): angular.IDirective {
        return {
            restrict: 'E',
            template: $templateCache.get("actobo-header.html"),
        }
    }

    static fxpLoader($window, $rootScope, $templateCache): angular.IDirective {
        var directive = {
            restrict: 'AE',
            replace: true,
            scope: true,
            template: $templateCache.get("pageLoader.template.html")
        };
        return directive;
    }

    static fxpbreadcrumb($templateCache): angular.IDirective {
        var directive = FxpComponentDirectives.getEmptyDirective();
        directive.template = $templateCache.get("fxpbreadcrumb.html");
        return directive;
    }

    static fxphelpmenu($templateCache): angular.IDirective {
        var directive = FxpComponentDirectives.getEmptyDirective();
        directive.template = $templateCache.get("fxphelpmenu.html");
        return directive;
    }

    static fxpnotification($templateCache): angular.IDirective {
        var directive = FxpComponentDirectives.getEmptyDirective();
        directive.template = $templateCache.get("fxpnotification.html")
        return directive;
    }

    static fxpsystemupdatemsg($templateCache): angular.IDirective {
        var directive = {
            restrict: 'E',
            template: $templateCache.get("system-update-msg-banner.html"),

        };
        return directive;
    }

    static fxptoastnotification($templateCache): angular.IDirective {
        var directive = {
            restrict: 'E',
            controller: 'ToastNotificationController',
            controllerAs: 'toastNotificationController',
            bindToController: true,
            template: $templateCache.get("fxptoastnotification.html")
        };
        return directive;
    }

    static fxpbotmenu($templateCache): angular.IDirective {
        var directive = FxpComponentDirectives.getEmptyDirective();
        directive.template = $templateCache.get("fxpbot.component.html");
        return directive;
    }

    static fxppagetour($templateCache): angular.IDirective {
        var directive = FxpComponentDirectives.getEmptyDirective();
        directive.template = $templateCache.get("pageTour.component.html");
        return directive;
    }
}
FxpComponentDirectives.fxpLoader.$inject = ['$window', '$rootScope', '$templateCache'];
FxpComponentDirectives.fxpfooter.$inject = ['DeviceFactory', '$templateCache'];
FxpComponentDirectives.fxpleftnavigation.$inject = ['$templateCache'];
FxpComponentDirectives.fxpheader.$inject = ['$templateCache'];
FxpComponentDirectives.fxpOboHeader.$inject = ['$templateCache'];
FxpComponentDirectives.fxpbreadcrumb.$inject = ['$templateCache'];
FxpComponentDirectives.fxphelpmenu.$inject = ['$templateCache'];
FxpComponentDirectives.fxpnotification.$inject = ['$templateCache'];
FxpComponentDirectives.fxpsystemupdatemsg.$inject = ['$templateCache'];
FxpComponentDirectives.fxptoastnotification.$inject = ['$templateCache'];
FxpComponentDirectives.fxpbotmenu.$inject = ['$templateCache'];
FxpComponentDirectives.fxppagetour.$inject = ['$templateCache'];