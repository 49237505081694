/**
 * @application  Fxp
 */
/**
 * @namespace Fxp.Controllers
 */
import { ILogger } from "../interfaces/ILogger";
import { IRootScope } from "../interfaces/IRootScope";
import { TelemetryConstants } from "../telemetry/TelemetryConst";

/**
     * A controller for FxpApp Support Ticket functionality.
     * @class Fxp.Controllers.CreateSupportTicketController
     * @classdesc A controller for FxpApp Support Ticket functionality.
     * @example <caption>
     *  //To Use CreateSupportTicketController
     *  angular.module('FxPApp').controller('CreateSupportTicketController', ['AnyDependency', CreateSupportTicketController]);
     *  function CreateSupportTicketController(AnyDependency){ AnyDependency.doSomething(); }
     */
export class CreateSupportTicketController {
	private currentRequestType: any;
	private requestTypeData: any;
	private modalStrings:any;
	private readonly sourceForTelemetry = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.CreateSupportTicketController`;
	constructor(
		private $rootScope: IRootScope,
		private $window: angular.IWindowService,
		private $uibModalInstance: any,
		private fxpLoggerService: ILogger,
		private defaultRequestType: any
	) {
		this.currentRequestType = "";
		this.modalStrings = this.$rootScope.fxpUIConstants.UIStrings.CreateSupportTicketModalStrings;
		// Get Request Types data.
		this.getRequestTypeData();
	}

	/**
	* Method to set requestTypeData.
	* @method Fxp.Controllers.CreateSupportTicketController.getRequestTypeData
	* @example <caption> Example to use getRequestTypeData</caption>
	* this.getRequestTypeData();
	*/
	private getRequestTypeData(): void {
		this.requestTypeData = this.$window.tenantConfiguration.HelpConfiguration.FxpHelpLinks[1].HelpLinks[0].HelpLinkData
		// Select default Request Type
		this.selectDefaultRequestType();
	}

	/**
	* Method to select default request type.
	* @method Fxp.Controllers.CreateSupportTicketController.selectDefaultRequestType
	* @example <caption> Example to use selectDefaultRequestType</caption>
	* this.selectDefaultRequestType();
	*/
	private selectDefaultRequestType(): void {
		if (this.defaultRequestType) {
			this.currentRequestType = this.requestTypeData.filter((requestType) => {
				return requestType.DisplayText == this.defaultRequestType;
			})[0];
		}
	}

	/**
	* Method to open support ticket in new tab and log the event.
	* @method Fxp.Controllers.CreateSupportTicketController.openPage
	* @example <caption> Example to use openPage</caption>
	* this.openPage();
	*/
	public openPage(): void {
		let propBag = this.fxpLoggerService.createPropertyBag();
		// Open link in new tab.
		this.$window.open(this.currentRequestType.Href, "_blank");
		// Log event.
		propBag.addToBag("RequestType", this.currentRequestType.DisplayText);
		propBag.addToBag("SnowLink", this.currentRequestType.Href);
		this.fxpLoggerService.logEvent(`${this.sourceForTelemetry}.openPage`, `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.CreateSupportTicketRequest`, propBag);
		// Close Modal.
		this.closeModal();
	}

	/**
	* Method to close Modal.
	* @method Fxp.Controllers.CreateSupportTicketController.closeModal
	* @example <caption> Example to use closeModal</caption>
	* this.closeModal();
	*/
	public closeModal(): void {
		this.$uibModalInstance.dismiss()
	}
}