export class AdminLandingDirective implements angular.IDirective {

	static adminLanding($timeout): angular.IDirective {
		return {
			restrict: 'A',
			link: function (scope, elem, attr) {
				$timeout(function () {
					let firstAdminTab = elem.find(".uib-tab")[0];
					if(firstAdminTab)
						firstAdminTab.focus();
				}, 100);
			}
		};
	}
}




