import { TelemetryConstants } from "../telemetry/TelemetryConst";
import { ILogger } from "../interfaces/ILogger";
import { ErrorCodes } from "../constants/errorCodes";
import { IFxpFeedbackMessage } from "../interfaces/IFxpFeedbackMessage";
import { IOCVFeedbackType, IOCVManifestType, IOCVSource } from "../common/enum/Feedback.enum";
import { FxpConfigurationService } from "./FxpConfiguration";
import { FxpOCVFeedbackService } from "./FxpOCVFeedbackService";
import { FxpFeedbackModalConstant } from "../constants/FxPModal.constants";
import { FxpMessageService } from "../../app/banner/FxpMessageService";
import { FxpGlobalStoreService } from "./fxp.global.store.service";
import { IRootScope } from "../interfaces/IRootScope";
import { IOCVFeedbackPayload } from "../interfaces/IOCVFeedbackPayload";
import { CommonUtils } from "../utils/CommonUtils";
import { UserInfoService } from "./UserInfoService";


export class UserFeedbackUIService {
	private readonly sourceForTelemetry: string = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.UserFeedbackUIService`; 
  
	constructor(private http: angular.IHttpService,private fxpOCVFeedbackService : FxpOCVFeedbackService,private userInfoService: UserInfoService,
        private fxpConfiguration: FxpConfigurationService,private $uibModal: any,
        private fxpLogger: ILogger, private fxpMessage: FxpMessageService, private fxpGlobalStoreService: FxpGlobalStoreService,private $rootScope: IRootScope){

    }
	
    private showFeedbackPopup(title: string, description: string, callback: any) {
        const modalProps: any = FxpFeedbackModalConstant.options;
        let upn = this.userInfoService.getCurrentUserUpn();
        const self = this;
        const source_telemetry = `${this.sourceForTelemetry}.showFeedbackSurveyPopup`;
        modalProps.controller = function ($scope,$uibModalInstance) {
          $scope.feedbackData = {};
          this.Title = title;
          this.Content = description;
          $scope.feedbackData.disableButton = true;
          this.onSubmit = _ => {
            self.submitFeedback($scope.feedbackData.feedbackMessage, $scope.feedbackData.feedbackRating/5, $scope.feedbackData.feedbackUserEmail, $uibModalInstance);
            self.$rootScope.IsModelOpen = false;   
          };
          this.emailIsChecked = function(){
            if($("input[name=shareEmail]").prop('checked')) {
              $scope.feedbackData.feedbackUserEmail = upn;
              $("#EmailInput").prop('disabled', false);
            }
            else {
              $scope.feedbackData.feedbackUserEmail = "";
              $("#EmailInput").prop('disabled', true);
            }
          };
          this.onCancel = _ => {           
            localStorage["GCEFeedbackLastReminder"] = new Date();
            $uibModalInstance.close('cancel');
            self.$rootScope.IsModelOpen = false;
            self.fxpLogger.logEvent(source_telemetry, `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.FeedbackPopupClosed`);  
          };
          this.ratingSelected = function(){
            if($scope.feedbackData.feedbackRating && $scope.feedbackData.feedbackRating != 0) {
              $scope.feedbackData.disableButton = false;
            }

          }
        };
    
        this.$uibModal.open(modalProps);
        
      }

    public collectGCEFeedback(){
      let self = this;
      const source_telemetry = `${this.sourceForTelemetry}.collectGCEFeedback`;
      let showGCEFeedbackDialog = self.fxpGlobalStoreService.GetPlatformState().FeatureFlags[this.fxpConfiguration.FxpAppSettings.ShowGCEFeedbackDialogFeatureFlagName];
			let GCEFeedbackDialogFeatureFlag = self.fxpGlobalStoreService.GetPlatformState().FeatureFlags[this.fxpConfiguration.FxpAppSettings.GCERingReleaseFeatureFlagName];
			let isFeedbackSubmitted = localStorage["GCEFeedbackSubmitted"] === "true" ? true : false;
			let LastFeedbackLaunchTimeStamp = localStorage["GCEFeedbackLastReminder"];
			let canShowFeedbackModal = true;
			if(LastFeedbackLaunchTimeStamp)
				canShowFeedbackModal = (new Date().getTime() - new Date(LastFeedbackLaunchTimeStamp).getTime())/ (1000 * 60 * 60) >= this.fxpConfiguration.FxpAppSettings.ShowGCEFeedbackAfterInterval_InHours;
			setTimeout(function () {
				if (showGCEFeedbackDialog && GCEFeedbackDialogFeatureFlag && !isFeedbackSubmitted && canShowFeedbackModal && !self.$rootScope.IsModelOpen) {
					self.showFeedbackPopup(self.fxpConfiguration.FxpBaseConfiguration.FxpConfigurationStrings.UIStrings.FeedbackPopupMessages.HeaderMessage, self.fxpConfiguration.FxpBaseConfiguration.FxpConfigurationStrings.UIStrings.FeedbackPopupMessages.FeedbackMessage, {});
          self.$rootScope.IsModelOpen = true;
          self.fxpLogger.logEvent(source_telemetry, `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.FeedbackPopupOpened`);  
        }
			}, 2000);
    }

    private submitFeedback(message, rating, userEmail, $uibModalInstance){
      const self = this;
      const source_telemetry = `${this.sourceForTelemetry}.submitFeedback`;
      let SendGCEFeedackToOCVFlag = self.fxpGlobalStoreService.GetPlatformState().FeatureFlags[this.fxpConfiguration.FxpAppSettings.SendGCEFeedackToOCV];
      let feedbackMessagesEndPoint = this.fxpConfiguration.FxpAppSettings.FxpServiceEndPoint + "/feedbackmessages";
      const feedbackMessage: IFxpFeedbackMessage = {
        feedbackManifestType: IOCVManifestType.Fps, // Manifest type for survey
        feedbackType: IOCVFeedbackType.Survey, // Feedback type
        feedbackMessage:  message, // user message
        partnerName: this.fxpConfiguration.FxpAppSettings.GCEFeedbackSurveyPartnerName, // sub area info // Has user given the consent to store the email address
        surveyId: this.fxpConfiguration.FxpAppSettings.GCEFeedbackDialogSurveyId, // survey id
        rating:  rating, // normalized rating 0-1
        email:  userEmail
      };
      if (SendGCEFeedackToOCVFlag) {
        this.fxpOCVFeedbackService.SubmitFeedback(feedbackMessage).then(function () {
          localStorage["GCEFeedbackSubmitted"] = true;
          $uibModalInstance.close('submit');
          self.fxpLogger.logEvent(source_telemetry, `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.GCEFeedbackSubmitted`);
          self.fxpMessage.addMessage("Your feedback has been submitted", "success");
        }).catch(function (error) {
          localStorage["GCEFeedbackSubmitted"] = false;
          $uibModalInstance.close('submit');
          self.fxpLogger.logError(source_telemetry, "Error while submitting the GCE OCV feedback.", ErrorCodes.Error_SubmittingFeedback, null);
          self.fxpMessage.addMessage("Error while submitting the feedback.", "error");
        });
      }
      else {
        let feedbackPayload: IOCVFeedbackPayload = {
          manifestType: feedbackMessage.feedbackManifestType,
          appId: this.fxpConfiguration.FxpAppSettings.OCVAppId,
          submitTime: new Date().toISOString(),
          source: IOCVSource.Client,
          type: feedbackMessage.feedbackType,
          rating: feedbackMessage.rating,
          clientFeedbackId: CommonUtils.getNewGuId(),
          comment: feedbackMessage.feedbackMessage,
          email: feedbackMessage.email,
          telemetry: {
              loggableUserId: this.fxpGlobalStoreService.GetPlatformState().CurrentUser.Claims.aadObjectId,
              featureArea: feedbackMessage.partnerName,
              officeBuild: this.fxpConfiguration.FxpAppSettings.BuildNumber,
              tenantId: this.fxpConfiguration.FxpAppSettings.TenantId
          },
          survey: {
              surveyId: feedbackMessage.surveyId
          },
          web: {
              browser: navigator["browserDetails"] ? navigator["browserDetails"].name : "",
              browserVersion: navigator["browserDetails"] ? navigator["browserDetails"].version : "",
              sourcePageName: document.getElementsByTagName("title")[0].innerHTML,
              sourcePageURI: window.location.href
          }
      };
        this.http.post(feedbackMessagesEndPoint, JSON.stringify(feedbackPayload), { headers: { 'Content-Type': 'application/json' } }).then(function () {
          localStorage["GCEFeedbackSubmitted"] = true;
          $uibModalInstance.close('submit');
          self.fxpLogger.logEvent(source_telemetry, `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.GCEFeedbackSubmitted`);
          self.fxpMessage.addMessage("Your feedback has been submitted", "success");
        }).catch(function (error) {
          localStorage["GCEFeedbackSubmitted"] = false;
          $uibModalInstance.close('submit');
          self.fxpLogger.logError(source_telemetry, "Error while submitting the GCE OCV feedback.", ErrorCodes.Error_SubmittingFeedback, null);
          self.fxpMessage.addMessage("Error while submitting the feedback.", "error");
        });
      }
    }
}