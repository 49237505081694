import { ILogger } from "../interfaces/ILogger";
import { PerfMarkers, ApplicationConstants, FxpConstants } from "../common/ApplicationConstants";
import { Resiliency } from "../resiliency/FxpResiliency";
import { TelemetryContext } from "../telemetry/telemetrycontext";
import { FxpContext } from "../context/FxpContext";
import { PageLoaderService } from "../../app/loader/pageLoaderService";
import { FxpRouteService } from "../services/FxpRouteService";
import { IRootScope } from "../interfaces/IRootScope";
import { AppControllerHelper } from "./AppControllerHelper";
import { IStateConfig } from "../interfaces/IStateConfig";
import { IRouteInfo } from "../interfaces/IRouteInfo";
import { PartnerAppRegistrationService } from "../services/PartnerAppRegistrationService";
import { UserInfoService } from "../services/UserInfoService";
import { IFxpAppContext } from "../interfaces/IFxpAppContext";
import { FxpConfigurationService } from "../services/FxpConfiguration";
import { PartnerContainerComponent } from "../../app/components/partnerContainer-component/partnerContainer-component";
import { FxpMessageService } from "../../app/banner/FxpMessageService";
import { IStateDataObject } from "../interfaces/IStateDataObject";
import { CommonUtils } from "../utils/CommonUtils";
import { DashboardService } from "../services/dashboardService";
import { ReactPartnerContainerComponent } from "../../app/components/reactContainer-component/reactpartner-component";
import { FxpGlobalStoreService } from "../services/fxp.global.store.service";
import { AddRouteConfiguration } from "@fxp/staterouter";
import { PowerBIReportContainerComponent } from "../../app/components/powerbi-component/powerbi-container-component";
import { StateParams } from "@uirouter/angular";
import { TelemetryConstants } from "../telemetry/TelemetryConst";
import { ComponentType } from "@microsoftit/telemetry-extensions-npm";
import { SystemEvent } from "../telemetry/SystemEvent";
import { ErrorCodes } from "../constants/errorCodes";
import { ErrorSeverityLevel } from "../telemetry/ErrorSeverityLevel";
import { FxpBroadcastedEvents } from "../services/FxpBroadcastedEvents";
import { UserFeedbackUIService } from "../services/UserFeedbackUIService";
import { UserAnnouncementUIService } from "../services/UserAnnouncementUIService";

declare type IStateService = any;
/**
 * @application  Fxp
 */

/**
   * A main factory which acts as an helper for DashBoardController. This is the factory having common functionalities.
   * @class Fxp.Factory.DashBoardHelper
   * @classdesc An helper factory for DashBoardController in FxPApp module
   * @example <caption>
   *  //How To use this factory
   *  angular.module('FxPApp').controller('DashBoardController', ['DashBoardHelper', DashBoardHelper]);
   *  function DashBoardController(AnyDependency){ DashBoardHelper.fillRoutes(config); }
   */
export class DashBoardHelper {
  private UIStateHelper: angular.IServiceProvider;
  private fxpLoggerService: ILogger;
  private $state: IStateService;
  private fxpContext: FxpContext;
  private fxpTelemetryContext: TelemetryContext;
  private fxpRouteService: FxpRouteService;
  private $rootScope: IRootScope;
  private $location: angular.ILocationService;
  private pageLoaderService: PageLoaderService;
  private $injector: angular.auto.IInjectorService;
  private appControllerHelper: AppControllerHelper;
  private stateConfigCollection: Array<IStateConfig>;
  private userInfoService: UserInfoService;
  private fxpMessageService: FxpMessageService;
  private enableLazyLoading: boolean
  private $q: angular.IQService
  private $interval: angular.IIntervalService;
  private maxWaitTimeInMs = 2000;
  private dashboardService: DashboardService;
  private sourceForTelemetry = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.DashBoardHelper`;

  constructor(
    $rootScope: IRootScope,
    $state: any, UIStateHelper: angular.IServiceProvider,
    fxpLoggerService: ILogger, fxpTelemetryContext: TelemetryContext,
    fxpContextService: FxpContext,
    fxpRouteService: FxpRouteService,
    $location: angular.ILocationService,
    PageLoaderService: PageLoaderService,
    private fxpConfigurationService: FxpConfigurationService,
    $injector: angular.auto.IInjectorService, appControllerHelper: AppControllerHelper, userInfoService: UserInfoService,
    fxpMessageService: FxpMessageService,
    $interval: angular.IIntervalService,
    $q: angular.IQService,
    DashboardService: DashboardService,
    private globalStore: FxpGlobalStoreService,
    private feedbackService: UserFeedbackUIService,
    private announcementService: UserAnnouncementUIService
  ) {
    this.$rootScope = $rootScope;
    this.UIStateHelper = UIStateHelper;
    this.fxpLoggerService = fxpLoggerService;
    this.$state = $state;
    this.fxpContext = fxpContextService;
    this.fxpTelemetryContext = fxpTelemetryContext
    this.fxpRouteService = fxpRouteService;
    this.$location = $location;
    this.pageLoaderService = PageLoaderService;
    this.$injector = $injector;
    this.appControllerHelper = appControllerHelper;
    this.userInfoService = userInfoService;
    this.fxpMessageService = fxpMessageService;
    var lazyLoadconfig = window["tenantConfiguration"].AdditionalConfigurationContainer || {};
    this.enableLazyLoading = lazyLoadconfig.EnableLazyLoading || false;
    this.$interval = $interval;
    this.$q = $q;
    this.dashboardService = DashboardService;
  }

  /**
* A method to get the basic profile.
* @method Fxp.Factory.DashBoardHelper.getBasicProfile(config)
* @param {Object} fxpConfig FxpRoute Configurations.
* @example <caption> Example to use fillRoutes</caption>
*  DashBoardHelper.fillRoutes(config);
*/
  public fillRoutes(fxpConfig: any): void {
    this.stateConfigCollection = new Array<IStateConfig>();
    if (fxpConfig && fxpConfig.Routes)
      this.updateStateConfiguration(fxpConfig.Routes);
    let fxpAppContext: IFxpAppContext = {
      UserContext: this.userInfoService.getCurrentUserContext()
    }
    var partnerRoutes: any = PartnerAppRegistrationService.getRegisteredRoutes(fxpAppContext);
    if (!partnerRoutes) {
      partnerRoutes = new Array<IStateConfig>();
    }
    partnerRoutes.push(this.getFxpWelcomeRoute());
    this.updateStateConfiguration(partnerRoutes);
    this.addAngularPartnerAppRoutes();
    if (this.stateConfigCollection.length > 0) {
      this.fxpContext.saveContext(ApplicationConstants.UIConfigDB, JSON.stringify(fxpConfig));
      this.$rootScope.configRouteStates = this.stateConfigCollection;
      this.fxpLoggerService.startTrackPerformance(PerfMarkers.LoadRoutes);

      this.fxpLoggerService.stopTrackPerformance(PerfMarkers.LoadRoutes);
      if (sessionStorage["startTime"] != null && sessionStorage["startTime"] != "undefined" && sessionStorage["startTime"] != "null") {
        var propbag = this.fxpLoggerService.createPropertyBag();
        var receiveDate = new Date();
        var startTime = new Date(Date.parse(sessionStorage["startTime"]));
        var responseTime = receiveDate.getTime() - startTime.getTime(); // in millisecs
        propbag.addToBag(FxpConstants.metricConstants.StartTime, startTime.toUTCString());
        propbag.addToBag(FxpConstants.metricConstants.EndTime, receiveDate.toUTCString());
        propbag.addToBag(FxpConstants.metricConstants.ServiceName, FxpConstants.metricConstants.FXPApplicationLaunchMetric);
        propbag.addToBag(FxpConstants.metricConstants.UserAgent, navigator.userAgent);
        propbag.addToBag(FxpConstants.metricConstants.SessionId, this.$rootScope.sessionId);
        propbag.addToBag(FxpConstants.metricConstants.UserUPN, this.fxpTelemetryContext.getUserID());
        propbag.addToBag(FxpConstants.metricConstants.UserBusinessRole, this.fxpTelemetryContext.getUserRole());
        propbag.addToBag(FxpConstants.metricConstants.Geography, this.fxpTelemetryContext.getGeography());
        this.fxpLoggerService.logMetric(this.sourceForTelemetry, FxpConstants.metricConstants.FXPApplicationLaunchTimeMetric, responseTime, propbag);
        sessionStorage["startTime"] = null;
      }

      let alias = this.userInfoService.getCurrentUser();

      var claimdata = this.globalStore.GetPlatformState().CurrentUser.Claims;
      var defaultRoutes = window["defaultRoutes"].DefaultRoutes;

      //Get all the states of the user role
      const { States } = defaultRoutes.find((item) => (item.AppRole == claimdata.defaultAppRole));
      var oldFlightState, newFlightState;
      if (States !== undefined && States !== null && States.length > 1) {
        oldFlightState = this.getStateName(States);
        this.setRoute(oldFlightState, States);
        this.dashboardService.getInitialFeatureFlagsForPlatform().then(() => {
          newFlightState = this.getStateName(States);
          if (JSON.stringify(oldFlightState) !== JSON.stringify(newFlightState)) {
            this.setRoute(newFlightState, States);
          }
          sessionStorage[ApplicationConstants.RequestStateName] = "";
        });
      } else {
        this.setRoute(oldFlightState, States);
        sessionStorage[ApplicationConstants.RequestStateName] = "";
      }
    }
  }

  private setRoute(flightstate, States) {
    const source_telemetry = `${this.sourceForTelemetry}.SetRoute`;
    var DefaultStateName;
    //Get the flighted state name or default state name, as applicable
    if (flightstate !== undefined && flightstate !== null) {
      DefaultStateName = flightstate.StateName;
    } else {
      DefaultStateName = States.find((item) => (item.FeatureName == "default")).StateName;
    }
    this.$rootScope.defaultStateName = DefaultStateName;
    let landingPage = this.$rootScope.defaultStateName;
    if (this.fxpConfigurationService.FxpAppSettings.EnableFxPWelcomePage) {
      landingPage = "fxpwelcome";
    }
    var dhref = this.$state.href(this.$rootScope.defaultStateName);
    if (dhref == null || dhref == undefined) {
      this.pageLoaderService.fnHidePageLoader();
      this.fxpMessageService.addMessage(`Default route ${DefaultStateName} is not configured`, FxpConstants.messageType.error);
      this.fxpLoggerService.logError(source_telemetry, `Default route ${DefaultStateName} is not configured`, ErrorCodes.DefaultStateNotConfigured, null, null, null, null, ErrorSeverityLevel.High);
    }
    else {
      this.UIStateHelper.otherwise(dhref.slice(1)); //$state.href(defaultStateName) returns # prefixed href e.g #/DashBoard. So we need to remove # prefix
      this.fxpRouteService.setDefaultStateName(this.$rootScope.defaultStateName);
      if (sessionStorage[ApplicationConstants.RequestStateName] != '' && sessionStorage[ApplicationConstants.RequestStateName] != '/') {
        location.hash = `#${sessionStorage[ApplicationConstants.RequestStateName]}`;
      }
      else {
        this.$rootScope.appBooted = true;
        this.pageLoaderService.fnShowPageLoader(this.$rootScope.fxpUIConstants.UIStrings.LoadingStrings.LoadingDashboard, this.appControllerHelper.handleAdalErrorsLoadingDashboard);
        (this.$state.current.name === landingPage) ? this.$state.reload() : this.$state.go(landingPage, {}, { location: "replace" });
      }

      let bag = this.fxpLoggerService.createPropertyBag();
      let currentProcessName = TelemetryConstants.FXP_TELEMETRY_PLATFORM_BOOTSTRAP + '.LandingExperience.FillRoutes';
      let eventData = new SystemEvent(currentProcessName, ComponentType.DataStore, 'Fill routes completed.');

      bag.addToBag('DefaultStateName', `${this.$rootScope.defaultStateName}`);
      if (this.$rootScope.actOnBehalfOfUserActive) {
        bag.addToBag("IsOBOMode", "True");
      }
      else {
        bag.addToBag("IsOBOMode", "False");
      }
      this.fxpLoggerService.logSystemEvent(source_telemetry, eventData, bag)
    }
    this.fxpLoggerService.stopTrackPerformance(PerfMarkers.DashboardLoad);
    this.loadOCVScripts();
    this.fxpLoggerService.stopTrackPerformance(PerfMarkers.FxpLoad);
    this.$rootScope.$broadcast(FxpBroadcastedEvents.OnPageLoadComplete, `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.Shell`);
    const shellLoadCompleteEvent = new SystemEvent(`${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.FxpShellLoadComplete`, ComponentType.Web, "FxP Shell loaded.");
    this.fxpLoggerService.logSystemEvent(source_telemetry, shellLoadCompleteEvent);
    this.collectFeedback();
  }
  private loadOCVScripts() {
    window["fxpOcvLoaded"], window["fxpOcvFloodGateLoaded"] = false;

    var ocvFileName = window["isDebugMode"] ? 'officebrowserfeedback.js' : 'officebrowserfeedback.min.js';
    var ocvFloodgateFileName = window["isDebugMode"] ? 'officebrowserfeedback_floodgate.js' : 'officebrowserfeedback_floodgate.min.js';

    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "lib/OCV/scripts/" + ocvFileName;; // officebrowserfeedback.js or officebrowserfeedback_floodgate.js
      js.onload = function () {
        window["fxpOcvLoaded"] = true;
      }
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'officebrowserfeedback-jssdk'));

    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "lib/OCV/scripts/" + ocvFloodgateFileName; // officebrowserfeedback.js or officebrowserfeedback_floodgate.js
      js.onload = function () {
        window["fxpOcvFloodGateLoaded"] = true;
      }
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'officebrowserfeedback-floodgate-jssdk'));
  }
  private collectFeedback() {
    let self = this;
    this.dashboardService.getInitialFeatureFlagsForPlatform().then(() => {
      self.announcementService.showUserAnnouncement();
      self.feedbackService.collectGCEFeedback();
    });
  }
  private getFxpWelcomeRoute() {
    const route = "{\"name\":\"fxpwelcome\",\"url\":\"/fxpwelcome\",\"templateUrl\":\"welcome.html\",\"controller\":\"FxpWelcomeController\",\"data\":{\"ocvAreaName\":\"FxpWelcomeArea\",\"headerName\":\"Enterprise Field Experience\",\"breadcrumbText\":\"Enterprise Field Experience\",\"pageTitle\":\"Enterprise Field Experience\",\"partnerTelemetryName\":\"Fxp\"}}";
    return JSON.parse(route);
  }

  private updateStateConfiguration(configRoutes) {
    var routeInfoList = <IRouteInfo[]>[];
    for (var i = 0; i < configRoutes.length; i++) {
      if (configRoutes[i].RouteConfig) {
        var routeConfig = typeof (configRoutes[i].RouteConfig) == "string" ? JSON.parse(configRoutes[i].RouteConfig) : configRoutes[i].RouteConfig;
        var currentStateObj = <IStateConfig>{};
        currentStateObj.name = configRoutes[i].StateName;
        currentStateObj.url = routeConfig.url;
        currentStateObj.controller = routeConfig.controller;
        currentStateObj.controllerAs = routeConfig.controllerAs;
        currentStateObj.templateUrl = routeConfig.templateUrl;
        currentStateObj.resolve = routeConfig.resolve;
        if (!routeConfig.powerbiReportConfig) {
          currentStateObj.component = routeConfig.component;
          currentStateObj.params = routeConfig.params || {};
        }
        else {
          currentStateObj.component = PowerBIReportContainerComponent;
          currentStateObj.params = new StateParams(routeConfig.powerbiReportConfig);
        }

        //backward compatibility to access params object for existed partners.
        currentStateObj.params.headerName = configRoutes[i].AppHeader;
        currentStateObj.params.breadcrumbText = configRoutes[i].BreadcrumbText;
        currentStateObj.params.pageTitle = configRoutes[i].PageTitle;
        currentStateObj.data = {
          partnerTelemetryName: configRoutes[i].PartnerTelemetryName,
          headerName: configRoutes[i].AppHeader,
          breadcrumbText: configRoutes[i].BreadcrumbText,
          pageTitle: configRoutes[i].PageTitle,
          requiredModules: configRoutes[i].requiredModules,
          LazyLoad: configRoutes[i].LazyLoad || [],
          stateModulesMissing: false,
          extendable: configRoutes[i].extendable || {},
          featureName: configRoutes[i].FeatureName || undefined,
          subFeatureName: configRoutes[i].SubFeatureName || undefined,
          actionName: configRoutes[i].ActionName,
          pageNameForTelemetry: configRoutes[i].PageNameForTelemetry,
          appNameForTelemetry: configRoutes[i].AppNameForTelemetry
        };
        currentStateObj.views = routeConfig.views;
        this.stateConfigCollection.push(currentStateObj);
        this.registerRoute(currentStateObj);
        if (routeInfoList.some(routeInfo => routeInfo.applicationName === currentStateObj.data.partnerTelemetryName)) {
          routeInfoList.find(routeInfo => routeInfo.applicationName === currentStateObj.data.partnerTelemetryName).routes.push(currentStateObj)
        } else {
          routeInfoList.push({
            applicationName: currentStateObj.data.partnerTelemetryName,
            routes: [currentStateObj],
            sharedBundles: null
          });
        }
      }
      else {
        // adding routes with new schema directly to collection
        let route;
        if (configRoutes[i].component) {

          route = configRoutes[i];
          route.childComponent = route.component;
          //route.component = PartnerContainerComponent;

          if (configRoutes[i].componentFramework != undefined && configRoutes[i].componentFramework == "React") {
            route.component = ReactPartnerContainerComponent;
          }
          else {
            route.component = PartnerContainerComponent;
          }
        }
        // if using sticky states feature, swap the named view component with PartnerContainerComponent
        if (configRoutes[i].sticky && configRoutes[i].views) {
          route = configRoutes[i];
          let name = Object.keys(route.views)[0]; // sticky:true requires there to be a named view in the `views` attribute
          route.childComponent = route.views[name].component;
          route.views[name].component = PartnerContainerComponent;
        }
        this.stateConfigCollection.push(configRoutes[i]);
        this.registerRoute(configRoutes[i]);
      }
    }
  }

  private registerRoute(routesData): void {
    try {
      this.updateResolveObjects(routesData, routesData.data.LazyLoad)
      var stateModulesMissing = false;
      try {
        if (routesData.data && routesData.data.requiredModules && Resiliency.UnavailablePartnerModules.length > 0) {
          var reqModules = routesData.data.requiredModules.split(',');
          if (reqModules.length > 0) {
            for (var i = 0; i < reqModules.length; i++) {
              if (Resiliency.UnavailablePartnerModules.indexOf(reqModules[i]) > -1) {
                stateModulesMissing = true;
                Resiliency.statesWithMissingModules.push(routesData.name);
                break;
              }
            }
          }
        }
      }
      catch (ex) { }
      routesData.data.stateModulesMissing = stateModulesMissing;
      var config = angular.extend({
        "requireADLogin": true
      }, routesData);
      if (!this.$state.get(routesData.name)) {
        this.UIStateHelper.addState(routesData.name, config);
      }
    }
    catch (ex) {
      this.fxpLoggerService.logException(`${this.sourceForTelemetry}.RegisterRoute`, ex, null, null, null, ErrorSeverityLevel.Medium, ErrorCodes.ErrorRegisteringRoute);
    }
  }

  /**
  * A method to get the basic profile.
  * @method Fxp.Factory.DashBoardHelper.getResolveMembers(config)
  * @param {Object} resolveObjects Resolve Objects.
  * @example <caption> Example to use getResolveMembers</caption>
  *  this.getResolveMembers(resolveObjects);
  */
  private getResolveMembers(resolveObjects: any): any {
    var promiseObjects = {};
    Object.keys(resolveObjects).forEach(function (key) {
      var value = resolveObjects[key];
      promiseObjects[key] = new Function(value); // [SM04509] legacy code
    });

    return promiseObjects;
  }

  /**
 * A method to get the basic profile.
 * @method Fxp.Factory.DashBoardHelper.updateResolveObjects(config)
 * @param {Object} uirouteConfigObjects UIRoute Config Objects.
 * @example <caption> Example to use updateResolveObjects</caption>
 *  this.updateResolveObjects(uirouteConfigObjects);
 */
  private updateResolveObjects(uirouteConfigObjects: any, lazyLoadModules: any): any {
    let self = this;
    const telemetry_source = `${this.sourceForTelemetry}.UpdateResolveObjects`;
    try {
      var ocResolve, getFlightingInfo;
      var getResolveMembers = this.getResolveMembers;
      if (this.enableLazyLoading && lazyLoadModules && lazyLoadModules.length) {
        ocResolve = ["$ocLazyLoad", "$injector", function ($ocLazyLoad, $injector) {
          var pgldrService = $injector.get('PageLoaderService');
          if (pgldrService != undefined)
            pgldrService.fnShowPageLoader("loading...");

          return $ocLazyLoad.load(lazyLoadModules, { serie: true }).then(function () {
            pgldrService.fnShowPageLoader("loaded...");
            pgldrService.fnHidePageLoader();
          }).catch(function (err) {
            pgldrService.fnHidePageLoader();
            let errMessage;
            if (err.message != undefined) {
              errMessage = err.message;
            }
            if (err.stack != undefined) {
              errMessage += " " + err.stack;
            }
            self.fxpLoggerService.logException(telemetry_source, err, null, null, null, ErrorSeverityLevel.High, ErrorCodes.ErrorInLazyLoading);
          });
        }];
        if (uirouteConfigObjects.resolve != undefined) {
          try {
            uirouteConfigObjects.resolve = eval(uirouteConfigObjects.resolve);
            uirouteConfigObjects.resolve = uirouteConfigObjects.resolve || {};
            uirouteConfigObjects.resolve.lazyLoad = ocResolve;
          }
          catch (ex) {
            this.handleRouteResolveException(lazyLoadModules, uirouteConfigObjects);
          }
        }
        else {
          uirouteConfigObjects.resolve = uirouteConfigObjects.resolve || {};
          uirouteConfigObjects.resolve.lazyLoad = ocResolve;
        }
      }
      else {
        if (uirouteConfigObjects.resolve != undefined) {
          uirouteConfigObjects.resolve = eval(uirouteConfigObjects.resolve);
        }
      }
      if (uirouteConfigObjects.views != undefined) {
        var stateViews = uirouteConfigObjects.views;
        Object.keys(stateViews).forEach(function (key) {
          var value = stateViews[key];
          if (value.resolve != undefined) {
            value.resolve = getResolveMembers(value.resolve);
          }
        });
      }
    } catch (ex) {
      this.fxpLoggerService.logException(telemetry_source, ex, null, null, null, ErrorSeverityLevel.High, ErrorCodes.ErrorUpdatingStateObject);
    }
  }

  private handleRouteResolveException(lazyLoadModules: any, uirouteConfigObjects: any): void {
    let self = this;
    let getFlightingInfo = ["$ocLazyLoad", "$injector", function ($ocLazyLoad, $injector) {
      var pgldrService = $injector.get('PageLoaderService');
      if (pgldrService != undefined)
        pgldrService.fnShowPageLoader("loading...");

      return $ocLazyLoad.load(lazyLoadModules, { serie: true }).then(function () {
        pgldrService.fnHidePageLoader();
        var featureFlagService = $injector.get('featureFlagService');
        return featureFlagService.GetFilghtingFeatures().then(null, null);

      }).catch(function (err) {
        pgldrService.fnHidePageLoader();
        self.fxpLoggerService.logException(`${self.sourceForTelemetry}.HandleRouteResolveException`, err, null, null, null, ErrorSeverityLevel.High, ErrorCodes.RouteResolveException);
      });
    }];
    uirouteConfigObjects.resolve = {};
    uirouteConfigObjects.resolve.getFlightingInfo = getFlightingInfo;
  }

  private addAngularPartnerAppRoutes(): void {
    if (PartnerAppRegistrationService.angularPartnerStateConfig.length > 0) {
      PartnerAppRegistrationService.angularPartnerStateConfig.forEach(partnerStateConfig => {
        let addRouteConfigurationAction = AddRouteConfiguration(partnerStateConfig);
        this.globalStore.DispatchGlobalAction("Fxp.DashboardHelper", addRouteConfigurationAction);
        let routes = partnerStateConfig.routes;
        routes.forEach(partnerRoute => {
          let route = <IStateConfig>{};
          route.url = partnerRoute.url;
          // if using sticky states feature, swap the named view component with PartnerContainerComponent
          if (partnerRoute.sticky && partnerRoute.views) {
            route.views = partnerRoute.views;
            let name = Object.keys(route.views)[0];
            route.views[name].component = PartnerContainerComponent;
          } else {
            //route.component = PartnerContainerComponent;

            if (partnerRoute.componentFramework != undefined && partnerRoute.componentFramework == "React") {
              route.component = ReactPartnerContainerComponent;
            }
            else {
              route.component = PartnerContainerComponent;
            }

          }
          route.sticky = partnerRoute.sticky;
          route.name = partnerRoute.name;
          route.params = partnerRoute.params;
          route.controllerAs = partnerRoute.controllerAs;
          route.includeRootModule = partnerRoute.includeRootModule;
          route.redirectTo = partnerRoute.redirectTo;
          route.template = partnerRoute.template;
          route.templateUrl = partnerRoute.templateUrl;
          route.authorizationRules = partnerRoute.authorizationRules;
          route.data = <IStateDataObject>{};
          route.data["partnerAppName"] = partnerStateConfig.applicationName;
          route.data.breadcrumbText = partnerRoute.data.breadcrumbText;
          route.data.headerName = partnerRoute.data.headerName;
          route.data.pageTitle = partnerRoute.data.pageTitle;
          route.data.style = partnerRoute.data.style;
          route.data.partnerTelemetryName = partnerRoute.data.partnerTelemetryName;
          route.data.requiredModules = partnerRoute.data.requiredModules;
          route.data.stateModulesMissing = partnerRoute.data.stateModulesMissing;
          route.data.extendable = partnerRoute.data.extendable;
          route.data.LazyLoad = this.getLazyLoadBundles(partnerStateConfig.sharedBundles, partnerRoute.specificBundles, partnerRoute.generatedBundle);
          route.data.appNameForTelemetry = partnerRoute.data.appNameForTelemetry;
          route.data.pageNameForTelemetry = partnerRoute.data.pageNameForTelemetry;
          route.data.featureName = partnerRoute.data.featureName;
          route.data.subFeatureName = partnerRoute.data.subFeatureName;
          route.data.actionName = partnerRoute.data.actionName;
          route.data.ocvAreaName = partnerRoute.data.ocvAreaName;
          this.registerRoute(route);
          this.stateConfigCollection.push(route);
        });
      });
    }
  }

  private getLazyLoadBundles(commonBundles: Array<string>, specificBundles: Array<string>, generatedBundle: string): Array<string> {
    let totalBundles = Array<string>();
    totalBundles = commonBundles ? [...totalBundles, ...commonBundles] : totalBundles;
    totalBundles = specificBundles ? [...totalBundles, ...specificBundles] : totalBundles;
    totalBundles = (generatedBundle && generatedBundle.length > 0) ? [...totalBundles, generatedBundle] : totalBundles;
    return totalBundles;
  }

  static DashBoardHelperFactory($rootScope, $state, UIStateHelper, fxpLoggerService, fxpTelemetryContext, fxpContextService, fxpRouteService, $location, PageLoaderService, FxpConfigurationService, $injector, appControllerHelper, userInfoService, FxpMessageService, $interval, $q, DashboardService, globalStore, feedbackService, announcementService) {
    return new DashBoardHelper($rootScope, $state, UIStateHelper, fxpLoggerService, fxpTelemetryContext, fxpContextService, fxpRouteService, $location, PageLoaderService, FxpConfigurationService, $injector, appControllerHelper, userInfoService, FxpMessageService, $interval, $q, DashboardService, globalStore, feedbackService, announcementService);
  }

  private getStateName(States): string {
    var featureFlagJson = localStorage["FeatureFlags"];
    var flightstate = null;
    //Get all feature flags which are true
    if (featureFlagJson !== undefined) {
      var FeatureFlags = JSON.parse(featureFlagJson);
      var arrFeatureFlag = [];
      Object.keys(FeatureFlags).map(function (key) {
        if (FeatureFlags[key])
          arrFeatureFlag.push(key);
        return arrFeatureFlag;
      });

      //Get feature flight state if any feature flag value is true
      flightstate = States.find((state) => {
        var isFeatureFlagExists = arrFeatureFlag.find((item) => (item == state.FeatureName));
        return isFeatureFlagExists !== undefined && isFeatureFlagExists !== null;
      });
    }
    return flightstate;
  }
}
