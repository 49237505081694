/**
 * @application  Fxp
 */
/**
 * @module Fxp.Controllers
 */
import { ILogger } from "../interfaces/ILogger";
import { FxpConstants } from "../common/ApplicationConstants";
import { FxpConfigurationService } from "../services/FxpConfiguration";
import { TelemetryConstants } from "../telemetry/TelemetryConst";
/**
   * Footer Controller. Used to display footer links and captures telemetry around it
   * @class Fxp.Controllers.FooterController
   * @classdesc FooterController of FxpApp module
   * @example <caption>
   *  //To Use FooterController
   *  angular.module('FxPApp').controller('FooterController', ['AnyDependency', FooterController]);
   *  function FooterController(AnyDependency){ AnyDependency.doSomething(); }
   */
export class FooterController {
	private readonly sourceForTelemetry = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.FooterController`;
	constructor(
		private $scope: any,
		private fxpLoggerService: ILogger,
		private fxpConfigurationService: FxpConfigurationService,
	) {
		this.$scope.footerdata = fxpConfigurationService.FxpBaseConfiguration.FxpFooterData;
		this.updateCopyRightDataWithCurrentYear();
		//Initializes value
		//updating userInfo session for logged in user only if RoleGroup Configuration is missing
		this.$scope.logFooterUsageTelemetryInfo = this.logFooterUsageTelemetryInfo.bind(this);
	}

	logFooterUsageTelemetryInfo(footerItem: any): void {
		var propBag = this.fxpLoggerService.createPropertyBag();
		propBag.addToBag(FxpConstants.metricConstants.FooterLinkUrl, footerItem.href);
		propBag.addToBag(FxpConstants.metricConstants.FooterLinkName, footerItem.DisplayText);
		this.fxpLoggerService.logUserAction(this.sourceForTelemetry, `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.FooterLinkClick`, "Footer link clicked by user", propBag); 
	}

	private updateCopyRightDataWithCurrentYear(): void {
		let copyRightData = this.$scope.footerdata.LeftAlignedLinks.find(data => { return data.TabIndex == '401' });
		let currentYear = new Date().getFullYear();
		copyRightData.DisplayText = `©${currentYear} ${copyRightData.DisplayText} `;
	}
}
