/**
 * @application  Fxp
 */
/**
 * @module Fxp.Controllers
 */
import {
  FxpConstants,
  ApplicationConstants,
} from "../common/ApplicationConstants";
import { IRootScope } from "../interfaces/IRootScope";
import { FxpConfigurationService } from "../services/FxpConfiguration";
import { NotificationStore } from "../services/NotificationStore";
import { NotificationActionCenter } from "../services/NotificationActionCenter";
import { ILogger } from "../interfaces/ILogger";
import { SettingsServiceProvider } from "../provider/SettingsServiceProvider";
import { UserInfoService } from "../services/UserInfoService";
import { FxpMessageService } from "../../app/banner/FxpMessageService";
import { FxpContext } from "../context/FxpContext";
import { CommonUtils } from "../utils/CommonUtils";
import { SettingsType } from "../common/SettingsType";
import { ISettingsService } from "../interfaces/ISettingsService";
import { FxpBroadcastedEvents } from "../services/FxpBroadcastedEvents";
import { TelemetryConstants } from "../telemetry/TelemetryConst";
import { ErrorCodes } from "../constants/errorCodes";
import { ErrorSeverityLevel } from "../telemetry/ErrorSeverityLevel";
import { FeatureUsageEvent } from "../telemetry/FeatureUsageEvent";
import {
  ActionStatus,
  ActionType,
  ComponentType,
  EventName,
} from "@microsoftit/telemetry-extensions-npm";
import { LogPropertyBag } from "../telemetry/LogPropertyBag";
import { FxpGlobalStoreService } from "../services/fxp.global.store.service";
import { HideLoader, ShowLoader } from "../../app/loader/loader.actions";
import { FxpSignalRService } from "../services/FxpSignalRClient";
import {
  DismissNotification,
  MarkNotification,
  PushNotification,
  ResetNotificationCollection,
} from "../../app/notifications/notifications.action";
import { IPlatformState } from "../../app/store/platform.state.model";
import { Notification } from "../../app/notifications/notifications.model";

/**
 * This is the controller having functionality and data for Notifications.
 * @class Fxp.Controllers.NotificationsController
 * @classdesc A controller of FxpApp module
 * @example <caption>
 *  //To Use NotificationsController
 *  angular.module('FxPApp').controller('NotificationsController', ['AnyDependency', NotificationsController]);
 *  function NotificationsController(AnyDependency){ AnyDependency.doSomething(); }
 */
export class NotificationsController {
  // Private variables
  private notificationLimit: number;
  private notificationList: any;
  private unreadNotificationCount: number;
  private notificationCollection: Array<any>;
  private isNotificationFlyoutOpen: boolean;
  private hasNotificationError: boolean;
  private notificationErrorMsg: any;
  private fxpConstants: FxpConstants;
  private notifcationCountPollInterval: number;
  private offsetExpirationInterval: number;
  private moreNotificationsAvailable: boolean;
  private unreadNotificationTimerValue: number = 10000;
  private signalREventName;
  private signalRTenant = ApplicationConstants.signalRTenant;
  private unsubscribeSignalRHandler: any;
  private sourceForTelemetry = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.NotificationsController`;
  private notificationFeatureName = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.Notifications`;

  constructor(
    private $rootScope: IRootScope,
    private $scope: angular.IScope,
    private $window: angular.IWindowService,
    private $interval: angular.IIntervalService,
    private fxpConfigurationService: FxpConfigurationService,
    private $timeout: any,
    private notificationStore: NotificationStore,
    private notificationActionCenter: NotificationActionCenter,
    private fxpLoggerService: ILogger,
    private settingsService: ISettingsService,
    private userInfoService: UserInfoService,
    private fxpMessage: FxpMessageService,
    private fxpContext: FxpContext,
    private device: any,
    private fxpGlobalStoreService: FxpGlobalStoreService,
    private fxpSignalRService: FxpSignalRService
  ) {
    //Initializes value
    var self = this;
    self.unreadNotificationCount = 0;
    self.signalREventName =
      self.fxpConfigurationService.FxpAppSettings.SignalREventWebNotificationName;
    self.notificationCollection = [];
    self.isNotificationFlyoutOpen = false;
    self.hasNotificationError = false;
    self.notificationErrorMsg = "";
    self.moreNotificationsAvailable = false;
    self.notifcationCountPollInterval = self.fxpConfigurationService
      .FxpBaseConfiguration.NotificationConfiguration
      ? self.fxpConfigurationService.FxpBaseConfiguration
          .NotificationConfiguration.PollingInterval || 5000
      : 5000;
    self.offsetExpirationInterval = self.fxpConfigurationService
      .FxpBaseConfiguration.NotificationConfiguration
      ? self.fxpConfigurationService.FxpBaseConfiguration
          .NotificationConfiguration.OffsetExpirationInterval || 1800000
      : 1800000;
    self.notificationLimit = self.fxpConfigurationService.FxpBaseConfiguration
      .NotificationConfiguration
      ? self.fxpConfigurationService.FxpBaseConfiguration
          .NotificationConfiguration.DefaultNotificationLimit || 20
      : 20;
    self.getNotifications(0, self.notificationLimit);
    self.subscribeToSignalR();
    self.fxpGlobalStoreService.SubscribeToPlatformState(
      "Platform",
      (platformState: IPlatformState) => {
        self.updateNotifications(platformState);
      }
    );
  }
  private updateNotifications(platformState: IPlatformState) {
    var self = this;
    if (
      platformState.Notifications &&
      platformState.Notifications.UserNotifications.length !=
        self.notificationCollection.length
    ) {
      self.notificationCollection =
        platformState.Notifications.UserNotifications;
    }
    if (
      platformState.Notifications &&
      platformState.Notifications.UnreadNotificationCount !=
        self.unreadNotificationCount
    ) {
      self.unreadNotificationCount =
        platformState.Notifications.UnreadNotificationCount;
    }
  }
  private subscribeToSignalR() {
    if (!this.unsubscribeSignalRHandler)
      this.unsubscribeSignalRHandler = this.fxpSignalRService.subscribe(
        this.signalREventName,
        this.signalRTenant,
        this.onNotificationReceived.bind(this)
      );
  }
  public unsubscribeToSignalR() {
    if (this.unsubscribeSignalRHandler) this.unsubscribeSignalRHandler();
    this.unsubscribeSignalRHandler = null;
  }
  private onNotificationReceived(response: any): any {
    if (!response || this.$rootScope.actOnBehalfOfUserActive) return;
    var NotificationsReceived = this.notificationCollection.filter(
      (x) => x.notificationId === response.id
    );
    if (NotificationsReceived.length != 0) return;
    NotificationsReceived =
      this.notificationActionCenter.appendPropertiesToNotifications([response]);
    var notification = NotificationsReceived[0] as Notification;
    notification.notificationId = NotificationsReceived[0].id;
    this.fxpGlobalStoreService.DispatchLocalAction(
      "Platform",
      PushNotification(notification)
    );
    if (this.isToastNotificationNeeded())
      this.$rootScope.$broadcast(
        FxpBroadcastedEvents.OnNewNotificationsRecieved,
        [response]
      );
  }
  /**
   * An event handler whenever notification flyout is closed using close icon.
   * @method Fxp.Controllers.NotificationsController.closeNotificationFlyout
   * @example <caption> Example to use closeNotificationFlyout</caption>
   * <div ng-click="closeNotificationFlyout()">Dropdown closed</div>;
   */
  closeNotificationFlyout(): void {
    var self = this;
    self.isNotificationFlyoutOpen = false;
    $("#notification-open").focus();
  }

  private logFeatureUsageEventObject(
    source: string,
    featureUsageEvent: FeatureUsageEvent,
    experienceResult: ActionStatus,
    properties?: LogPropertyBag
  ) {
    featureUsageEvent.ActionStatus = experienceResult;
    this.fxpLoggerService.logFeatureUsageEvent(
      source,
      featureUsageEvent,
      properties
    );
  }

  /**
   *A method to Get the notifications
   * @method Fxp.Controllers.NotificationsController.getNotifications
   * @param {number} index is an index from where notifications are to be fetched.
   * @param {number} count is a number of records to be fetched.
   * @param {number?} currentIndex is an index where focus is now.
   * @example <caption> Example to use getNotifications</caption>
   * NotificationsController.getNotifications()
   */
  getNotifications = function (
    index,
    count,
    setFocusToFirstElement?,
    renewCorrelationId?: boolean
  ) {
    // Start time for performance calculation.
    const self = this;
    const startTime = performance.now(),
      propbag = self.fxpLoggerService.createPropertyBag();
    let actionName = "ViewNotifications";
    const source = `${self.sourceForTelemetry}.getNotifications`;

    if (!renewCorrelationId) {
      self.fxpLoggerService.renewCorrelationId();
    } else {
      self.fxpLoggerService.renewSubCorrelationId();
      actionName = "ViewMoreNotifications";
    }
    const getNotificationsFeature = new FeatureUsageEvent(
      self.notificationFeatureName,
      ActionType.User,
      actionName,
      EventName.ButtonClick,
      ComponentType.Web
    );
    self.notificationStore.getNotifications(index, count).then(
      (response) => {
        var data = response.data;
        data =
          self.notificationActionCenter.appendPropertiesToNotifications(data);
        self.moreNotificationsAvailable = data.length == count;
        var unreadNotifications = data.filter(
          (item) => item.status.name != "Read"
        );
        self.fxpGlobalStoreService.DispatchLocalAction(
          "Platform",
          ResetNotificationCollection({
            UserNotifications: data,
            UnreadNotificationCount: unreadNotifications.length,
          })
        );
        self.removeErrorMessage();
        if (setFocusToFirstElement) {
          $(".notification-subject:first").focus();
        } else {
          $("#closeNotification").focus(); //focus will go to close button
        }

        propbag.addToBag("Total Time", performance.now() - startTime);
        self.fxpLoggerService.logEvent(
          source,
          `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.GetNotifications`,
          propbag
        );
        self.logFeatureUsageEventObject(
          source,
          getNotificationsFeature,
          ActionStatus.Succeeded,
          propbag
        );
      },
      (error) => {
        self.logError(
          error,
          self.$rootScope.fxpUIConstants.UIMessages.NotificationGetServiceError,
          ErrorCodes.GetNotifications_Failure
        );
        self.logFeatureUsageEventObject(
          source,
          getNotificationsFeature,
          ActionStatus.Failed,
          propbag
        );
      }
    );
  };

  getStoredNotification() {
    return this.fxpGlobalStoreService.GetPlatformState().Notifications
      .UserNotifications;
  }

  /**
   *A method to check whether toast notifications are needed
   * @method Fxp.Controllers.NotificationsController.isToastNotificationNeeded
   * @param {number} offset is an id last notifications fetched.
   * @example <caption> Example to use isToastNotificationNeeded</caption>
   * NotificationsController.isToastNotificationNeeded()
   */
  isToastNotificationNeeded(): boolean {
    var self = this,
      result =
        !self.device.isMobile() &&
        !self.isNotificationFlyoutOpen &&
        !self.$rootScope.isNotificationDNDEnabled &&
        !self.$rootScope.actOnBehalfOfUserActive;
    return result;
  }

  /**
   *A method to mark all notifications as read
   * @method Fxp.Controllers.NotificationsController.markAllNotificationsAsRead
   * @example <caption> Example to use markAllNotificationsAsRead</caption>
   * <div ng-click="markAllNotificationsAsRead()">markAllNotificationsAsRead()</div>
   */
  markAllNotificationsAsRead(): void {
    // Start time for performance calculation.
    const source = `${this.sourceForTelemetry}.markAllNotificationsAsRead`;
    const startTime = performance.now(),
      self = this,
      propbag = self.fxpLoggerService.createPropertyBag();

    self.fxpLoggerService.renewSubCorrelationId();
    const markAllFeature = new FeatureUsageEvent(
      self.notificationFeatureName,
      ActionType.User,
      "MarkAllNotificationsAsRead",
      EventName.LinkClicked,
      ComponentType.Web
    );
    self.fxpGlobalStoreService.DispatchGlobalAction(
      "Platform",
      ShowLoader({ loadingText: "Marking All Notifications as Read" })
    );
    self.notificationStore.markNotificationAsRead().then(
      (response) => {
        var markNotifications = self.notificationCollection;
        markNotifications.forEach(function (item) {
          item.status.id = 2;
          item.status.name = "Read";
          item.status.description = "The notification has been read";
        });
        self.fxpGlobalStoreService.DispatchLocalAction(
          "Platform",
          ResetNotificationCollection({
            UserNotifications: markNotifications,
            UnreadNotificationCount: 0,
          })
        );
        CommonUtils.pullFocusToElement("btn_dismissall");
        self.fxpGlobalStoreService.DispatchGlobalAction(
          "Platform",
          HideLoader({})
        );
        propbag.addToBag(
          "Total Time",
          (performance.now() - startTime).toString()
        );
        self.fxpLoggerService.logEvent(
          `${self.sourceForTelemetry}.markAllNotificationsAsRead`,
          `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.MarkAllNotificationsAsRead`,
          propbag
        );
        self.logFeatureUsageEventObject(
          source,
          markAllFeature,
          ActionStatus.Succeeded,
          propbag
        );
      },
      (error) => {
        self.logError(
          error,
          self.$rootScope.fxpUIConstants.UIMessages
            .NotificationReadServiceError,
          ErrorCodes.MarkAllNotificationsAsRead_Failure
        );
        self.logFeatureUsageEventObject(
          source,
          markAllFeature,
          ActionStatus.Failed,
          propbag
        );
      }
    );
  }
  /**
   *A method to delete all notifications
   * @method Fxp.Controllers.NotificationsController.dismissAllNotifications
   * @example <caption> Example to use dismissAllNotifications</caption>
   * <div ng-click="dismissAllNotifications()">dismissAll()</div>
   */
  dismissAllNotifications(): void {
    // Start time for performance calculation.
    const startTime = performance.now(),
      self = this,
      propbag = self.fxpLoggerService.createPropertyBag();
    self.fxpLoggerService.renewSubCorrelationId();
    const dismisAllFeature = new FeatureUsageEvent(
      self.notificationFeatureName,
      ActionType.User,
      "DismissAllNotifications",
      EventName.LinkClicked,
      ComponentType.Web
    );
    self.fxpGlobalStoreService.DispatchGlobalAction(
      "Platform",
      ShowLoader({ loadingText: "Dismissing All Notifications" })
    );
    self.notificationStore.deleteNotification(-1).then(
      (response) => {
        self.moreNotificationsAvailable = false;
        self.fxpGlobalStoreService.DispatchLocalAction(
          "Platform",
          ResetNotificationCollection({
            UserNotifications: [],
            UnreadNotificationCount: 0,
          })
        );

        CommonUtils.pullFocusToElement("closeNotification");
        self.fxpGlobalStoreService.DispatchGlobalAction(
          "Platform",
          HideLoader({})
        );
        propbag.addToBag(
          "Total Time",
          (performance.now() - startTime).toString()
        );
        self.fxpLoggerService.logEvent(
          `${self.sourceForTelemetry}.dismissAllNotifications`,
          `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.DismissAllNotifications`,
          propbag
        );
        self.logFeatureUsageEventObject(
          `${self.sourceForTelemetry}.dismissAllNotifications`,
          dismisAllFeature,
          ActionStatus.Succeeded,
          propbag
        );
      },
      (error) => {
        self.logError(
          error,
          self.$rootScope.fxpUIConstants.UIMessages
            .NotificationDeleteServiceError,
          ErrorCodes.DismissAllNotifications_Failure
        );
        self.logFeatureUsageEventObject(
          `${self.sourceForTelemetry}.dismissAllNotifications`,
          dismisAllFeature,
          ActionStatus.Failed,
          propbag
        );
      }
    );
  }
  /**
   *A method to load notifications based on the default limit
   * @method Fxp.Controllers.NotificationsController.loadMore
   * @param {any} event is a DOM Object that generated on action.
   * @example <caption> Example to use loadMore</caption>
   * <div ng-click="loadMore(event)">load More</div>
   */
  loadMore(event): void {
    const self = this;
    event.stopPropagation();
    event.preventDefault();
    const totalNotificationCount = self.notificationCollection.length;
    self.getNotifications(
      totalNotificationCount,
      self.notificationLimit,
      true,
      false
    );
  }
  /**
   *A method to  set the focus on available notification subject
   * @method Fxp.Controllers.NotificationsController.setFocusOnNotification
   * @param {number} notificationItemIndex is a notification number which specifies Notification limit before loading more.
   * @example <caption> Example to use setFocusOnNotification</caption>
   * NotificationsController.setFocusOnNotification(notificationItemIndex)
   */
  setFocusOnNotification(notificationItemIndex): void {
    const notificationList = $("li.notification");
    notificationItemIndex =
      notificationItemIndex < notificationList.length
        ? notificationItemIndex
        : notificationList.length - 1;
    $(notificationList[notificationItemIndex])
      .find(".notification-subject")
      .focus();
  }

  /**
   *A method to make the unread notifications to read
   * @method Fxp.Controllers.NotificationsController.readNotification
   * @param {any} item is a notification object to be read.
   * @example <caption> Example to use readNotification</caption>
   * <div ng-click="readNotification(item)">load More</div>
   */
  readNotification(item): void {
    // Start time for performance calculation.
    const startTime = performance.now(),
      self = this,
      propbag = self.fxpLoggerService.createPropertyBag();
    self.fxpLoggerService.renewSubCorrelationId();
    const source = `${self.sourceForTelemetry}.readNotification`;
    const readNotificationFeature = new FeatureUsageEvent(
      self.notificationFeatureName,
      ActionType.User,
      "MarkNotificationRead",
      EventName.LinkClicked,
      ComponentType.Web
    );
    try {
      self.notificationActionCenter.excecuteNotificationAction(item);
      if (item != undefined && item != null && item.status.name == "Sent") {
        self.notificationStore.markNotificationAsRead(item.notificationId).then(
          (response) => {
            const notificationIndex = self.notificationCollection.indexOf(item);
            var markNotification =
              self.notificationCollection[notificationIndex];
            markNotification.status.name = "Read";
            var notificaitonMsg = <HTMLInputElement>(
              document.getElementById("notificationAssertMessage")
            );
            notificaitonMsg.innerHTML =
              "<div>Marked notification as read" +
              notificationIndex +
              1 +
              " of " +
              self.notificationCollection.length +
              1 +
              "</div>";

            self.fxpGlobalStoreService.DispatchLocalAction(
              "Platform",
              MarkNotification(markNotification)
            );
            propbag.addToBag("EventId", item.eventId);
            propbag.addToBag("Notification Id", item.notificationId);
            propbag.addToBag("From", item.senderAddress);
            propbag.addToBag(
              "Total Time",
              (performance.now() - startTime).toString()
            );
            self.fxpLoggerService.logEvent(
              source,
              `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.ReadNotification`,
              propbag
            );
            self.logFeatureUsageEventObject(
              source,
              readNotificationFeature,
              ActionStatus.Succeeded,
              propbag
            );
          },
          (error) => {
            self.logError(
              error,
              self.$rootScope.fxpUIConstants.UIMessages
                .NotificationReadServiceError,
              ErrorCodes.ReadNotification_Failure
            );
            self.logFeatureUsageEventObject(
              source,
              readNotificationFeature,
              ActionStatus.Failed,
              propbag
            );
          }
        );
      }
    } catch (error) {
      self.logError(
        error,
        self.$rootScope.fxpUIConstants.UIMessages.NotificationReadServiceError,
        ErrorCodes.ReadNotification_Failure
      );
    }
  }

  /**
   *A method to  delete the notificaiton based on the webnotification id
   * @method Fxp.Controllers.NotificationsController.deleteNotification
   * @param {any} item is a notification object to be deleted.
   * @param {any} event is a DOM Object that generated on action.
   * @example <caption> Example to use deleteNotification</caption>
   * NotificationsController.deleteNotification()
   */
  deleteNotification = function (item, event) {
    // Start time for performance calculation.
    const startTime = performance.now(),
      self = this;
    self.fxpLoggerService.renewSubCorrelationId();
    const source = `${self.sourceForTelemetry}.deleteNotification`;
    const deleteNotificationFeature = new FeatureUsageEvent(
      self.notificationFeatureName,
      ActionType.User,
      "DeleteNotification",
      EventName.ButtonClick,
      ComponentType.Web
    );
    event.stopPropagation();
    event.preventDefault();
    const propbag = self.fxpLoggerService.createPropertyBag();
    self.notificationStore.deleteNotification(item.notificationId).then(
      (response) => {
        var itemIndex = self.notificationCollection.indexOf(item);
        if (itemIndex >= 0) {
          var dismissNotification = self.notificationCollection[itemIndex];
          var deleteNotificationMsg = <HTMLInputElement>(
            document.getElementById("notificationAssertMessage")
          );
          deleteNotificationMsg.innerHTML =
            "<div> Deleting notification message " +
            item.subject +
            " " +
            itemIndex +
            1 +
            " of " +
            self.notificationCollection.length +
            "</div>";
          self.fxpGlobalStoreService.DispatchLocalAction(
            "Platform",
            DismissNotification(dismissNotification)
          );
          propbag.addToBag("EventId", item.eventId);
          propbag.addToBag("Notification Id", item.notificationId);
          propbag.addToBag("From", item.senderAddress);
          propbag.addToBag("Total Time", performance.now() - startTime);
          self.fxpLoggerService.logEvent(
            source,
            `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.DeleteNotification`,
            propbag
          );
          self.logFeatureUsageEventObject(
            source,
            deleteNotificationFeature,
            true,
            propbag
          );
        }
      },
      (error) => {
        self.logError(
          error,
          self.$rootScope.fxpUIConstants.UIMessages
            .NotificationDeleteServiceError,
          ErrorCodes.DeleteNotification_Failure
        );
        self.logFeatureUsageEventObject(
          source,
          deleteNotificationFeature,
          false,
          propbag
        );
      }
    );
  };

  /**
   *A method to set the error message on notificaiton flyout
   * @method Fxp.Controllers.NotificationsController.setErrorMessage
   * @param {string}  msg is a error message to be shown on notificaiton flyout
   * @example <caption> Example to use setErrorMessage</caption>
   * NotificationsController.setErrorMessage(msg)
   */
  setErrorMessage = function (msg) {
    var self = this;
    self.hasNotificationError = true;
    self.notificationErrorMsg = msg;
  };

  /**
   *A method to remove the error message on notificaiton flyout
   * @method Fxp.Controllers.NotificationsController.removeErrorMessage
   * @example <caption> Example to use removeErrorMessage</caption>
   * NotificationsController.removeErrorMessage(msg)
   */
  removeErrorMessage = function () {
    var self = this;
    self.hasNotificationError = false;
    self.notificationErrorMsg = "";
  };
  /**
   *A method to log error
   * @method Fxp.Controllers.NotificationsController.logError
   * @param {any} error is a error object
   * @example <caption> Example to use sortObject</caption>
   * NotificationsController.logError(error)
   */
  logError(error, message, code): void {
    var self = this;
    var propbag = self.fxpLoggerService.createPropertyBag();
    propbag.addToBag(FxpConstants.metricConstants.Status, error.status);
    propbag.addToBag(FxpConstants.metricConstants.StatusText, error.statusText);
    self.fxpLoggerService.logError(
      `${self.sourceForTelemetry}.LogError`,
      message.ErrorMessageTitle,
      code,
      null,
      propbag,
      null,
      null,
      ErrorSeverityLevel.Medium
    );
    self.setErrorMessage(message.ErrorMessage);
  }

  /**
   *A method on DND Setting value change
   * @method Fxp.Controllers.NotificationsController.onDNDValueChange
   * @param {boolean} value is a boolean value
   * @example <caption> Example to use onDNDValueChange</caption>
   * NotificationsController.onDNDValueChange(true)
   */
  onDNDValueChange(value: boolean): void {
    var self = this;
    self.$rootScope.isNotificationDNDEnabled = value;
    self.saveNotificationDNDSetting(value);
  }

  /**
   *A method to save the Notification DND Setting
   * @method Fxp.Controllers.NotificationsController.saveNotificationDNDSetting
   * @param {boolean} value is a boolean value
   * @example <caption> Example to use saveNotificationDNDSetting</caption>
   * NotificationsController.saveNotificationDNDSetting(true)
   */
  saveNotificationDNDSetting(value: boolean): void {
    let self = this;
    const source_telemetry = `${this.sourceForTelemetry}.SaveNotificationDNDSetting`;
    let userAlias = self.userInfoService.getLoggedInUser();
    var propbag = self.fxpLoggerService.createPropertyBag();
    let userPreferencesStorageKey =
      ApplicationConstants.UserPreferencesStorageKey.replace("{0}", userAlias);
    self.fxpContext
      .readContext(userPreferencesStorageKey, ApplicationConstants.FxPDbName)
      .then(function (context) {
        let userPreferences =
          context && context.Result ? JSON.parse(context.Result) : {};
        userPreferences.isNotificationDNDEnabled = value;
        let strUserPreferences = JSON.stringify(userPreferences);
        let saveSettingsAPIStartTime = performance.now();
        self.settingsService
          .saveSettings(
            SettingsType.User,
            userAlias,
            ApplicationConstants.UserPreferencesSettings,
            strUserPreferences
          )
          .then(
            function (response) {
              var saveSettingsAPIDuration =
                performance.now() - saveSettingsAPIStartTime;
              var notificationDNDStatus =
                userPreferences.isNotificationDNDEnabled
                  ? "Enabled"
                  : "Disabled";
              propbag.addToBag("NotificationDNDStatus", notificationDNDStatus);
              propbag.addToBag(
                FxpConstants.metricConstants.SaveSettingsAPIResponseDuration,
                saveSettingsAPIDuration.toString()
              );
              self.fxpLoggerService.logEvent(
                source_telemetry,
                `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.NotificationDNDStatusChange`,
                propbag
              );
              self.fxpContext.saveContext(
                userPreferencesStorageKey,
                strUserPreferences,
                ApplicationConstants.FxPDbName
              );
            },
            function (error) {
              propbag.addToBag(
                FxpConstants.metricConstants.Status,
                error.status
              );
              propbag.addToBag(
                FxpConstants.metricConstants.StatusText,
                error.statusText + " " + error.data
              );
              self.fxpMessage.addMessage(
                self.$rootScope.fxpUIConstants.UIMessages
                  .DNDSaveSettingsFailedError.ErrorMessage,
                FxpConstants.messageType.error
              );
              self.fxpLoggerService.logError(
                source_telemetry,
                self.$rootScope.fxpUIConstants.UIMessages
                  .DNDSaveSettingsFailedError.ErrorMessageTitle,
                ErrorCodes.SaveNotificationDNDSetting,
                null,
                propbag
              );
            }
          );
      });
  }
}
