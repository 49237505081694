import { UserInfoService } from "../services/UserInfoService";
import { FxpConfigurationService } from "../services/FxpConfiguration";
import { ILogger } from "../interfaces/ILogger";
import { IRootScope } from "../interfaces/IRootScope";
import { CommonUtils } from "../utils/CommonUtils";
import { TelemetryConstants } from "../telemetry/TelemetryConst";
import { ErrorCodes } from "../constants/errorCodes";
import { ErrorSeverityLevel } from "../telemetry/ErrorSeverityLevel";
import { FxpGlobalStoreService } from "../../js/services/fxp.global.store.service";
import { InteractionRequiredAuthError, PopupRequest, RedirectRequest, SilentRequest } from "@azure/msal-browser";
import { FxpBootstrap } from "../boot/fxpboot";
import { PartnerAppRegistrationService } from "../services/PartnerAppRegistrationService";
import { MsalAuthenticationService, MsalAuthenticationServiceProvider } from "../services/MsalAuthenticationService";

const HttpInterceptorTelemetrySource = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.FxpHttpInterceptor`;
export function httpCorrelationInterceptor(userInfoService: UserInfoService,
	fxpConfigurationService: FxpConfigurationService,
	loggerService: ILogger,
	globalStoreService: FxpGlobalStoreService) {

	var grmInterceptorConfig = window["tenantConfiguration"].AdditionalConfigurationContainer || {};
	var authExcludeExtnPat = new RegExp(fxpConfigurationService.FxpBaseConfiguration.FxpConfigurationStrings.AdalAuthExcludeExtn, "i");
	var enableLazyLoading = grmInterceptorConfig.EnableLazyLoading || false;
	var requestInterceptor = {
		request(config) {
			if (authExcludeExtnPat.test(config.url) || isAddHeaders(config)) {
				delete config.headers["Authorization"];
				return config;
			}
			if (enableLazyLoading) {
				if (grmInterceptorConfig.InterceptGRMCalls && grmInterceptorConfig.GRM_APIMServiceUrl != undefined && config.url.indexOf(grmInterceptorConfig.GRM_APIMServiceUrl) >= 0) {
					if (config.headers['X-SenderId'] == undefined) {
						config.headers['X-SenderId'] = 'GRM';
						config.headers['X-SenderApp'] = 'GRM User Experience';
					}
					if (config.headers['Ocp-Apim-Subscription-Key'] == undefined) {
						config.headers['Ocp-Apim-Subscription-Key'] = grmInterceptorConfig.GRM_APIM_SubscriptionKey || "";
					}
				}
			}
			let transactionId = loggerService.getCorrelationId();
			config.headers["X-CorrelationId"] = transactionId;
			if(fxpConfigurationService.FxpAppSettings.MakeFaultFeatureFlagEvaluateCall){
				config.headers["X-faultEnabled"] = globalStoreService.GetPlatformState().FeatureFlags[fxpConfigurationService.FxpAppSettings.FaultFeatureFlagName];

			}

			config.headers["X-LoggedInUser-Alias"] = userInfoService.getLoggedInUser();
			config.headers["X-LoggedInUser-Id"] = userInfoService.getLoggedInUserOID();
			if (userInfoService.isActingOnBehalfOf()) {
				config.headers["X-ActonBehalfMode"] = 'true';
				config.headers["X-OnBehalfOfUser"] = userInfoService.getCurrentUserUpn();
				config.headers["X-OnBehalfOfUser-Alias"] = userInfoService.getCurrentUser();
				config.headers["X-OnBehalfOfUser-Id"] = userInfoService.getCurrentUserOID();
			}

			return config;
		}
	};

	function isAddHeaders(config) {
		var FxpAppSettings = FxpAppSettings || window["FxpAppSettings"] || {};
		var simpleEndpoints = FxpAppSettings.CorsExcludedDomains;
		var excludedDomains = [];
		if (simpleEndpoints) {
			excludedDomains = simpleEndpoints.split(';');
		}

		for (var i = 0; i < excludedDomains.length; i++) {
			if (config.url.indexOf(excludedDomains[i]) > -1) {
				return true;
			}
		}

		return false;
	}

	return requestInterceptor;
}

export function httpRetryInterceptor($q: angular.IQService,
	$injector: angular.auto.IInjectorService,
	$timeout: angular.IHttpInterceptorFactory,
	fxpConfigurationService: FxpConfigurationService,
	loggerService: ILogger,
	$rootScope: IRootScope) {
	var requestURL,
		retries = {},
		waitBetweenErrors = fxpConfigurationService.FxpBaseConfiguration.HttpRetryWaitTime || 1000,
		maxRetries = fxpConfigurationService.FxpBaseConfiguration.HttpRetryCount || 3;
	var excludedHttpStatusCodesForLogging = [];
	if (!CommonUtils.isNullOrEmpty(fxpConfigurationService.FxpAppSettings.ExcludeHttpStatusCodesFromLogging)) {
		excludedHttpStatusCodesForLogging = fxpConfigurationService.FxpAppSettings.ExcludeHttpStatusCodesFromLogging.split(',');
	}

	function onResponseError(httpConfig) {
		return $timeout(function () {
			var $http: any = $injector.get('$http');
			return $http(httpConfig);
		}, waitBetweenErrors);
	}

	return {
		request: function (config) {
			requestURL = config.url;
			config["beginTimeStamp"] = performance.now();
			return config;
		},
		response: function (response) {
			//logResourceTiming("ResourceTimingSuccess", response.config.url, response.status);
			let self = this;
			if (retries[response.config.url])
				delete retries[response.config.url];

			if (response.config && response.config.beginTimeStamp) {
				logPerfMetric(response.config.url, performance.now() - response.config.beginTimeStamp);
			}
			return response;
		},
		responseError: function (response) {
			let specificError = '';
			let propBag = loggerService.createPropertyBag();
			if (response != null) {
				var config;
				if (response.config) {
					config = response.config;
					propBag.addToBag("Status", response.status);
					propBag.addToBag("StatusText", response.statusText);
				}
				else {
					if (response.data && (response.data.indexOf("Token renewal") > -1 || response.data.indexOf("Token Renewal") > -1)) {
						config = response;
						propBag.addToBag("Error-Data", response.data);
						response["customStatus"] = response.status || -1;
					}
				}
				if (config && config.url) {
					logResourceTiming("ResourceTimingFailure", config.url, response.status);
					var retryForUrlCount = retries[config.url] || 0;
					var retryEnabled = config.retryEnabled === undefined ? true : config.retryEnabled;

					propBag.addToBag("ServiceUrl", config.url);

					if (retryEnabled && (response.status === -1 || response.customStatus === -1)) {
						if (retryForUrlCount < maxRetries) {
							retryForUrlCount++;
							retries[config.url] = retryForUrlCount;
							return onResponseError(config);
						}
						else {
							specificError = 'ServiceCallRetryExit';
						}
					} else {
						specificError = 'ServiceCallFailed';
					}

					delete retries[config.url];
				}
				else {
					specificError = 'response was not appropriate.';
					propBag.addToBag('Message', `responseError function does not return response as object, response value: "  ${CommonUtils.objectToString(response)}`);
					propBag.addToBag("ServiceUrl", requestURL);
				}
			}
			else {
				specificError = 'response is null.';
				propBag.addToBag('Message', `responseError function does not return response`);
				propBag.addToBag("ServiceUrl", requestURL);
			}
			let statusCode = (response.status) ? response.status.toString() : '0';
			if (excludedHttpStatusCodesForLogging.indexOf(statusCode) === -1) {
				let message: string = `Error in Fxp Http Interceptor due to ${specificError}.`;
				if (statusCode === "-1" || statusCode == "0") {	// CORS error or server was unreachable
					message.concat("The request was either aborted or the server was unreachable. Please check your CORS configuration and firewall settings on the server.");
				}
				const responseData = JSON.parse(JSON.stringify(response));
				if(responseData?.config && responseData?.config?.headers && responseData?.config?.headers?.Authorization){
					responseData.config.headers.Authorization = "REDACTED";
				}
				loggerService.logError(`${HttpInterceptorTelemetrySource}.HttpRetryInterceptor`, message, ErrorCodes.HttpInterceptorResponseError, CommonUtils.objectToString(responseData), propBag, null, null, ErrorSeverityLevel.High);
			}
			return $q.reject(response);
		}
	};

	function logPerfMetric(url: string, value: number) {
		if (url.indexOf('html') > 0 && value == 0) return; //value 0 means it is coming from template cache. 
		const properties = loggerService.createPropertyBag();
		setTimeout(function () {
			if (!isNaN(value)) {
				value = parseFloat((value / 1000).toFixed(1));
				properties.addToBag("Unit", "Seconds");
			}
			loggerService.logMetric(`${HttpInterceptorTelemetrySource}.HttpInterceptor`, url, value, properties);
		}, 0);
	}

	function logResourceTiming(message, url, status) {
		if (fxpConfigurationService.FxpAppSettings.EnableHttpTransactionTimeLogging) {
			var resourcePerf: any = window.performance.getEntries().filter(function getFilteredRecords(event) { return event.name.indexOf(url) > -1 });
			for (var i = 0; i < resourcePerf.length; i++) {
				var dns = resourcePerf[i].domainLookupEnd - resourcePerf[i].domainLookupStart,//capture domain lookup start
					tcp = resourcePerf[i].connectEnd - resourcePerf[i].connectStart,// TCP handshake
					ttfb = resourcePerf[i].responseStart - resourcePerf[i].startTime,//time to take first byte
					transfer = resourcePerf[i].responseEnd - resourcePerf[i].responseStart,
					total = resourcePerf[i].responseEnd - resourcePerf[i].startTime;// total time taken
				if (total > 0 && ttfb > 0) {
					var propBag = loggerService.createPropertyBag();
					propBag.addToBag("ResourceName", url);
					propBag.addToBag("Status", status);
					propBag.addToBag("DNS", dns.toString());
					propBag.addToBag("TCP", tcp.toString());
					propBag.addToBag("TTFB", ttfb.toString());
					propBag.addToBag("Transfer", transfer.toString());
					propBag.addToBag("Total", total.toString());
					loggerService.logEvent(`${HttpInterceptorTelemetrySource}`, message, propBag);
				}
			}
		}
	}
}

export function MsalHttpRequestInterceptor($q: angular.IQService, msalAuthenticationService: MsalAuthenticationService) {
	return {
		request: async function (config) {
			var token = (await msalAuthenticationService.acquireTokenAsPromise(config.url)).accessToken;
			if (token) { config.headers.Authorization = "Bearer " + token; }
			return config;
		},
		requestError: function (rejection) {
			return $q.reject(rejection);
		},
		response: function (result) {
			return result;
		},
		responseError: function (response) {
			return $q.reject(response);
		}
	};
}
