declare type IStateService = any;
import { IActOnBehalfOfControllerScope } from "../interfaces/IActOnBehalfOfControllerScope";
import { ILogger } from "../interfaces/ILogger";
import { FxpMessageService } from "../../app/banner/FxpMessageService";
import { FxpConstants } from "../common/ApplicationConstants";
import { OBOUserService } from "../services/OBOUserService";
import { IFxpContext } from "../interfaces/IFxpContext";
import { IRootScope } from "../interfaces/IRootScope";
import { UserProfileService } from "../services/userProfileService";
import { UserInfoService } from "../services/UserInfoService";
import { LogPropertyBag } from "../telemetry/LogPropertyBag";
import { TelemetryContext } from "../telemetry/telemetrycontext";
import { FxpConfigurationService } from "../services/FxpConfiguration";
import { DashboardService } from "../services/dashboardService";
import { CommonUtils } from "../utils/CommonUtils";
import { ActOnBehalfOfHelper } from "../factory/ActOnBehalfOfHelper";
import { FxpBroadcastedEvents } from "../services/FxpBroadcastedEvents";
import { FxpGlobalStoreService } from "../services/fxp.global.store.service";
import { UI_LOADER_NAMES } from "../../app/loader/UILoader.constants";
import {
  HideSpecificLoader,
  ShowSpecificLoader,
} from "../../app/loader/loader.actions";
import { TelemetryConstants } from "../telemetry/TelemetryConst";
import { ErrorCodes } from "../constants/errorCodes";
import { ErrorSeverityLevel } from "../telemetry/ErrorSeverityLevel";
import { FeatureUsageEvent } from "../telemetry/FeatureUsageEvent";
import {
  ActionStatus,
  ActionType,
  ComponentType,
  EventName,
} from "@microsoftit/telemetry-extensions-npm";

const ActOnBehalfOfFeatureName = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.ActOnBehalfOf`;
export class ActOnBehalfOfController {
  private $state: IStateService;
  private $rootScope: IRootScope;
  private $scope: IActOnBehalfOfControllerScope;
  private userProfileService: UserProfileService;
  private userInfoService: UserInfoService;
  private fxpLoggerService: ILogger;
  private fxpMessage: FxpMessageService;
  private ActOnBehalfOfHelper: ActOnBehalfOfHelper;
  private OBOUserService: OBOUserService;
  private fxpContext: IFxpContext;
  private fxpErrorConstants: any;
  private loggedInUser: string;
  private oboMetric: LogPropertyBag;
  private currentDate: Date;
  private fxpTelemetryContext: TelemetryContext;
  private fxpConfigurationService: FxpConfigurationService;
  private dashboardService: DashboardService;
  private sourceForTelemetry: string = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.ActOnBehalfOfController`;
  private actOnBehalfOfFeature: FeatureUsageEvent;

  constructor(
    $state: IStateService,
    $rootScope: IRootScope,
    $scope: IActOnBehalfOfControllerScope,
    userProfileService: UserProfileService,
    userInfoService: UserInfoService,
    fxpLoggerService: ILogger,
    fxpMessage: FxpMessageService,
    ActOnBehalfOfHelper: ActOnBehalfOfHelper,
    OBOUserService: OBOUserService,
    fxpContext: IFxpContext,
    fxpTelemetryContext: TelemetryContext,
    fxpConfigurationService: FxpConfigurationService,
    dashboardService: DashboardService,
    private fxpGlobalStoreService: FxpGlobalStoreService
  ) {
    var pageLoadStartTime = performance.now();
    this.$state = $state;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.userProfileService = userProfileService;
    this.userInfoService = userInfoService;
    this.fxpLoggerService = fxpLoggerService;
    this.fxpMessage = fxpMessage;
    this.ActOnBehalfOfHelper = ActOnBehalfOfHelper;
    this.OBOUserService = OBOUserService;
    this.fxpContext = fxpContext;
    this.fxpErrorConstants = $rootScope.fxpUIConstants.UIMessages;
    this.loggedInUser = userInfoService.getLoggedInUser();
    this.oboMetric = ActOnBehalfOfHelper.getPropBag();
    this.fxpTelemetryContext = fxpTelemetryContext;
    this.fxpConfigurationService = fxpConfigurationService;
    this.dashboardService = dashboardService;

    //Initializes value
    this.$scope.footerdata =
      this.fxpConfigurationService.FxpBaseConfiguration.FxpFooterData;
    this.$scope.oboUIStrings =
      this.$rootScope.fxpUIConstants.UIStrings.OBOUIStrings;

    this.$scope.displayErrorMessage = false;
    this.$scope.searchUsersList = [];
    this.$scope.errorMessage = "";
    this.$scope.selectedUser = "";
    this.$scope.selectedUserObject = {};
    this.$scope.isValidUserSelected = false;
    this.$scope.showViewFullProfileLink =
      window["tenantConfiguration"].ShowFullProfile;
    this.$scope.profileStateName = window["tenantConfiguration"].ViewProfileUrl;
    this.fxpLoggerService.renewCorrelationId();
    this.actOnBehalfOfFeature = new FeatureUsageEvent(
      ActOnBehalfOfFeatureName,
      ActionType.User,
      "ActivateOBO",
      EventName.ButtonClick,
      ComponentType.Web
    );
    this.fxpLoggerService.startFeatureUsageEvent(this.actOnBehalfOfFeature);

    this.$scope.reset = this.reset.bind(this);
    this.$scope.handleEnterKeyOnSearchUserInput =
      this.handleEnterKeyOnSearchUserInput.bind(this);
    this.$scope.actOnBehalfOfUserClick = this.actOnBehalfOfUserClick.bind(this);
    this.$scope.OBOAdminstratorClick = this.OBOAdminstratorClick.bind(this);
    this.$scope.searchUser = this.searchUser.bind(this);
    this.$scope.onSelect = this.onSelect.bind(this);
    this.$scope.createProfile = this.createProfile.bind(this);
    this.$scope.pullFocusToElement = CommonUtils.pullFocusToElement.bind(this);

    if ($rootScope.actOnBehalfOfUserActive) {
      var msg = this.fxpErrorConstants.OBOUserActive.ErrorMessage;
      msg = msg.replace(
        "OBOusername",
        this.OBOUserService.getOBOUser().fullName
      );

      this.fxpMessage.addMessage(
        msg,
        FxpConstants.messageType.error,
        undefined,
        undefined,
        undefined,
        "OBO_FormControl"
      );
      this.fxpLoggerService.logError(
        `${this.sourceForTelemetry}.Constructor`,
        this.fxpErrorConstants.OBOUserActive.ErrorMessage,
        ErrorCodes.OBO_User_Already_Active,
        null,
        null,
        null,
        null,
        ErrorSeverityLevel.Low
      );
    }
    var pageLoadEndTime = performance.now();
    var pageLoadDuration = pageLoadEndTime - pageLoadStartTime;
    this.fxpLoggerService.logPageLoadMetrics(pageLoadDuration);
    $("ul.dropdown-menu").attr("aria-label", "User Search Results");
  }

  reset() {
    var self = this;
    var propBag = self.ActOnBehalfOfHelper.getPropBag();
    self.fxpLoggerService.logEvent(
      `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.ActOnBehalfOfController.reset`,
      `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.OBOResetClickEvent`,
      propBag
    );
    self.$scope.selectedUser = "";
    self.$scope.displayErrorMessage = false;
    self.$scope.userProfileDoesNotExist = false;
    self.$scope.isValidUserSelected = false;
    self.$scope.selectedUserObject = {};
    /*using tab and Enter using Enter Key on reset Button shifting focusing to Type ahead Search Box. */
    var oboFormControl = $("#OBO_FormControl");
    if (!CommonUtils.isNullOrEmpty(oboFormControl)) {
      oboFormControl.focus();
    }
  }

  actOnBehalfOfUserClick() {
    var self = this;
    const source = `${this.sourceForTelemetry}.ActOnBehalfOfUserClick`;
    if (!self.$scope.userProfileDoesNotExist) {
      self.fxpLoggerService.logTrace(
        source,
        "actOnBehalfofuser(alias) Started"
      );
      var propbag = self.fxpLoggerService.createPropertyBag();
      try {
        var selectedUser = self.$scope.selectedUserObject;
        if (selectedUser) {
          if (selectedUser.Alias != self.loggedInUser) {
            if (selectedUser.RoleGroupID > 0) {
              self.fxpGlobalStoreService.DispatchGlobalAction(
                "Platform",
                ShowSpecificLoader({
                  name: UI_LOADER_NAMES.FXP_LOADERS.ACT_ON_BEHALF_OF_LOADER,
                  loadingText: "Loading",
                })
              );
              return self.ActOnBehalfOfHelper.getUserProfileAndClaims(
                selectedUser
              )
                .then(function (selectedUserData) {
                  var userClaims = self.userInfoService.getCurrentUserClaims();
                  if (
                    userClaims == undefined ||
                    (userClaims.Applications != undefined &&
                      Object.keys(userClaims.Applications).length <= 0)
                  ) {
                    self.fxpMessage.addMessage(
                      self.fxpErrorConstants.SelectedProfileRoles.ErrorMessage,
                      FxpConstants.messageType.error,
                      undefined,
                      undefined,
                      undefined,
                      "OBO_FormControl"
                    );
                    self.userInfoService.setCurrentUserOID(
                      self.userInfoService.getLoggedInUserOID()
                    );
                    self.userProfileService.setCurrentUser(self.loggedInUser);
                    self.userInfoService.setCurrentUserUpn(
                      self.userInfoService.getLoggedInUserUpn()
                    );
                    self.userInfoService.setActingOnBehalfOfFlag(false);
                    self.fxpGlobalStoreService.DispatchGlobalAction(
                      "Platform",
                      HideSpecificLoader({
                        name: UI_LOADER_NAMES.FXP_LOADERS
                          .ACT_ON_BEHALF_OF_LOADER,
                      })
                    );
                    self.OBOUserService.setCurrentUserStateFromLoggedInUserState();
                    return;
                  }

                  var OBOUser = {
                    fullName: selectedUserData.fullName,
                    displayName: selectedUserData.DisplayName,
                    alias: selectedUserData.alias,
                    href:
                      self.$scope.profileStateName +
                      "/" +
                      selectedUserData.alias +
                      "/basic",
                    businessRoleId: selectedUserData.businessRoleId,
                    businessRole: selectedUserData.businessRole,
                    roleGroupId: selectedUserData.roleGroupId,
                    roleGroupName: selectedUserData.roleGroupName,
                    defaultAppRole: userClaims.defaultAppRole,
                    aadObjectId: selectedUserData.AadObjectId,
                  };

                  self.ActOnBehalfOfHelper.getObOUserConfiguration(
                    userClaims.defaultAppRole
                  ).then(
                    function success(response) {
                      var onbehalfUserRoutes = response.data.UiConfiguration;
                      var oboUserSessionInfo = JSON.stringify(
                        self.userInfoService.getCurrentUserProfile()
                      );
                      var OBOEntity = {
                        OBOUserStatus: true,
                        OBOUserRoutes: onbehalfUserRoutes,
                        OBOUser: OBOUser,
                        OBOUserSessionInfo: oboUserSessionInfo,
                      };
                      self.OBOUserService.saveOBOEntityInContext(OBOEntity);
                      self.$scope.OBOUser = OBOUser;
                      //Telemetry
                      self.fxpTelemetryContext.addToGlobalPropertyBag(
                        FxpConstants.OBOConstants.ActonBehalfMode,
                        "true"
                      );
                      self.fxpTelemetryContext.addToGlobalPropertyBag(
                        FxpConstants.OBOConstants.OnBehalfOfUserUpn,
                        userClaims.upn
                      );
                      self.fxpTelemetryContext.addToGlobalPropertyBag(
                        FxpConstants.OBOConstants.OnBehalfOfUserBusinessRole,
                        selectedUserData.businessRole
                      );
                      self.fxpTelemetryContext.addToGlobalPropertyBag(
                        FxpConstants.OBOConstants.OnBehalfOfUserRoleGroup,
                        selectedUserData.roleGroupName
                      );
                      var oboStartTime = new Date().getTime().toString();
                      localStorage.setItem(
                        FxpConstants.OBOConstants.ActonBehalfofAdminStartTime.toString(),
                        oboStartTime
                      );
                      propbag.addToBag(
                        FxpConstants.OBOConstants.ActonBehalfofAdminStartTime,
                        oboStartTime
                      );
                      self.fxpGlobalStoreService.DispatchGlobalAction(
                        "Platform",
                        HideSpecificLoader({
                          name: UI_LOADER_NAMES.FXP_LOADERS
                            .ACT_ON_BEHALF_OF_LOADER,
                        })
                      );
                      self.fxpLoggerService.logEvent(
                        source,
                        `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.AdminOnBehalfOfStart`,
                        propbag
                      );
                      self.fxpLoggerService.logTrace(
                        source,
                        "actOnBehalfofuserclick End"
                      );
                      //if obo user fxp configuration is loaded then broadcast the UserContextChanged
                      self.$rootScope.$broadcast(
                        FxpBroadcastedEvents.OnUserContextChanged,
                        OBOUser.alias,
                        OBOUser.roleGroupId,
                        OBOUser.businessRoleId
                      );
                      self.$rootScope.fxpBreadcrumb = [];
                      self.actOnBehalfOfFeature.ActionStatus =
                        ActionStatus.Succeeded;
                      self.fxpLoggerService.endFeatureUsageEvent(
                        source,
                        self.actOnBehalfOfFeature
                      );
                    },
                    function errorCallback(response) {
                      propbag.addToBag("Error", response);
                      self.fxpGlobalStoreService.DispatchGlobalAction(
                        "Platform",
                        HideSpecificLoader({
                          name: UI_LOADER_NAMES.FXP_LOADERS
                            .ACT_ON_BEHALF_OF_LOADER,
                        })
                      );
                      self.OBOUserService.removeOBOUserContext();
                      self.userInfoService.setCurrentUserOID(
                        self.userInfoService.getLoggedInUserOID()
                      );
                      self.userProfileService.setCurrentUser(self.loggedInUser);
                      self.userInfoService.setCurrentUserUpn(
                        self.userInfoService.getLoggedInUserUpn()
                      );
                      self.userInfoService.setActingOnBehalfOfFlag(false);
                      self.fxpMessage.addMessage(
                        self.fxpErrorConstants.OBOUserUIConfigurationError
                          .ErrorMessage,
                        FxpConstants.messageType.error,
                        undefined,
                        undefined,
                        undefined,
                        "OBO_FormControl"
                      );
                      self.fxpLoggerService.logError(
                        source,
                        self.fxpErrorConstants.OBOUserUIConfigurationError
                          .ErrorMessage,
                        ErrorCodes.Get_OBO_User_Configuration_Failure,
                        null,
                        propbag,
                        null,
                        null,
                        ErrorSeverityLevel.Medium
                      );
                      self.$rootScope.$broadcast(
                        FxpBroadcastedEvents.OnUserContextChanged,
                        self.userInfoService.getLoggedInUser(),
                        self.userInfoService.getCurrentUserData().roleGroupId,
                        self.userInfoService.getCurrentUserData().businessRoleId
                      );
                      self.actOnBehalfOfFeature.ActionStatus =
                        ActionStatus.Failed;
                      self.fxpLoggerService.endFeatureUsageEvent(
                        source,
                        self.actOnBehalfOfFeature
                      );
                    }
                  );
                })
                .catch(function (err) {
                  self.userInfoService.setCurrentUserOID(
                    self.userInfoService.getLoggedInUserOID()
                  );
                  self.userProfileService.setCurrentUser(self.loggedInUser);
                  self.userInfoService.setCurrentUserUpn(
                    self.userInfoService.getLoggedInUserUpn()
                  );
                  self.userInfoService.setActingOnBehalfOfFlag(false);
                  self.fxpGlobalStoreService.DispatchGlobalAction(
                    "Platform",
                    HideSpecificLoader({
                      name: UI_LOADER_NAMES.FXP_LOADERS.ACT_ON_BEHALF_OF_LOADER,
                    })
                  );
                  self.actOnBehalfOfFeature.ActionStatus = ActionStatus.Failed;
                  self.fxpLoggerService.endFeatureUsageEvent(
                    source,
                    self.actOnBehalfOfFeature
                  );
                });
            } else {
              self.userInfoService.setCurrentUserOID(
                self.userInfoService.getLoggedInUserOID()
              );
              self.userProfileService.setCurrentUser(self.loggedInUser);
              self.userInfoService.setCurrentUserUpn(
                self.userInfoService.getLoggedInUserUpn()
              );
              self.userInfoService.setActingOnBehalfOfFlag(false);
              self.fxpMessage.addMessage(
                self.fxpErrorConstants.UserProfileBusinessRoleFailureError
                  .ErrorMessage,
                FxpConstants.messageType.error,
                undefined,
                undefined,
                undefined,
                "OBO_FormControl"
              );
              self.fxpLoggerService.logWarning(
                source,
                self.fxpErrorConstants.UserProfileBusinessRoleFailureError
                  .ErrorMessage
              );
              self.actOnBehalfOfFeature.ActionStatus = ActionStatus.Failed;
              self.fxpLoggerService.endFeatureUsageEvent(
                source,
                self.actOnBehalfOfFeature
              );
            }
          } else {
            self.$scope.errorMessage =
              self.$rootScope.fxpUIConstants.UIStrings.SelectedUserisCurrentLoggedInUserError;
            self.$scope.displayErrorMessage = true;
          }
        } else {
          self.$scope.userProfileDoesNotExist = true;
          self.$scope.errorMessage =
            self.$rootScope.fxpUIConstants.UIStrings.ProfileNotFound;
        }
      } catch (ex) {
        self.fxpGlobalStoreService.DispatchGlobalAction(
          "Platform",
          HideSpecificLoader({
            name: UI_LOADER_NAMES.FXP_LOADERS.ACT_ON_BEHALF_OF_LOADER,
          })
        );
        self.fxpMessage.addMessage(
          self.fxpErrorConstants.GeneralExceptionError.ErrorMessage,
          FxpConstants.messageType.error,
          undefined,
          undefined,
          undefined,
          "OBO_FormControl"
        );
        self.userInfoService.setCurrentUserOID(
          self.userInfoService.getLoggedInUserOID()
        );
        self.userProfileService.setCurrentUser(self.loggedInUser);
        self.userInfoService.setCurrentUserUpn(
          self.userInfoService.getLoggedInUserUpn()
        );
        self.userInfoService.setActingOnBehalfOfFlag(false);
        self.actOnBehalfOfFeature.ActionStatus = ActionStatus.Failed;
        self.fxpLoggerService.endFeatureUsageEvent(
          source,
          self.actOnBehalfOfFeature
        );
      }
    }
  }

  OBOAdminstratorClick() {
    var self = this;
    //Reset and move to landing page
    self.OBOUserService.removeOBOUserContext();
    self.$rootScope.navigateToLandingPage();
  }

  searchUser(value: string) {
    var self = this;
    const source = `${this.sourceForTelemetry}.SearchUser`;
    var rootScope = self.$rootScope;
    var startDate = new Date();
    return self.userProfileService.searchProfile(value).then(
      (data) => {
        var usersList = [];
        var endDate = new Date();
        if (data.data && data.data.length > 0) {
          self.$scope.displayErrorMessage = false;
          self.$scope.errorMessage = "";
          usersList = data.data;
        } else {
          self.$scope.displayErrorMessage = false;
          self.$scope.selectedUserObject = {};
          self.$scope.errorMessage =
            rootScope.fxpUIConstants.UIStrings.ProfileNotFound;
        }
        var responseTime = endDate.getTime() - startDate.getTime();
        var propbag = self.ActOnBehalfOfHelper.getMetricPropBag(
          startDate,
          endDate
        );
        self.fxpLoggerService.logMetric(
          source,
          FxpConstants.metricConstants.SearchProfileServiceTime,
          responseTime,
          propbag
        );
        return usersList;
      },
      (err) => {
        self.$scope.selectedUserObject = {};
        var endDate = new Date();
        var propbag: any;
        var notificaitonMsg = <HTMLInputElement>(
          document.getElementById("searchErrorMessage")
        );
        if (err.data.ErrorCode == 112) {
          self.$scope.errorMessage =
            rootScope.fxpUIConstants.UIStrings.ProfileNotFound;
          propbag = self.fxpLoggerService.createPropertyBag();
          propbag.addToBag("SearchUser", self.$scope.selectedUser);
          if (notificaitonMsg) {
            notificaitonMsg.innerHTML =
              "<div>" + self.$scope.errorMessage + "</div>";
          }
          self.fxpLoggerService.logInformation(
            source,
            rootScope.fxpUIConstants.UIMessages.SearchProfileSvcReturnsError
              .ErrorMessage,
            propbag
          );
        } else {
          self.$scope.errorMessage =
            rootScope.fxpUIConstants.UIStrings.ErrorRetrieving;
          if (notificaitonMsg) {
            notificaitonMsg.innerHTML =
              "<div>" + self.$scope.errorMessage + "</div>";
          }
          self.fxpLoggerService.logError(
            source,
            rootScope.fxpUIConstants.UIMessages.SearchProfileSvcNotWorkingError
              .ErrorMessage,
            ErrorCodes.OBO_User_Search_Failure,
            null,
            null,
            null,
            null,
            ErrorSeverityLevel.Medium
          );
        }

        var responseTime = endDate.getTime() - startDate.getTime();
        propbag = self.ActOnBehalfOfHelper.getMetricPropBag(startDate, endDate);
        self.fxpLoggerService.logMetric(
          source,
          FxpConstants.metricConstants.SearchProfileServiceErrorTime,
          responseTime,
          propbag
        );
      }
    );
  }

  handleEnterKeyOnSearchUserInput(event: KeyboardEvent) {
    var self = this;
    self.$scope.isValidUserSelected = false;
    if (event.key.toLowerCase() == "enter") {
      self.$scope.isValidUserSelected = true;
    } else if (
      (event.key == "Tab" || event.key.startsWith("Arrow")) &&
      self.$scope.selectedUserObject.Alias
    ) {
      self.$scope.isValidUserSelected = true;
    }
  }

  onSelect($item) {
    var self = this;
    self.$scope.selectedUserObject = $item;
    self.$scope.isValidUserSelected = true;
  }

  createProfile() {
    const source = `${this.sourceForTelemetry}.createProfile`;
    var self = this;
    var propBag = self.ActOnBehalfOfHelper.getPropBag();
    self.fxpLoggerService.logEvent(
      source,
      `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.CreateProfile`,
      propBag
    );

    var profileStatename =
      self.$rootScope.fxpUIConstants.UIStrings.CreateProfileStateName;
    var profileState = self.$state.get(profileStatename);
    if (profileState) self.$state.go(profileStatename);
    else {
      self.fxpMessage.addMessage(
        self.fxpErrorConstants.CreateProfileState.ErrorMessage,
        FxpConstants.messageType.error,
        undefined,
        undefined,
        undefined,
        "OBO_FormControl"
      );
    }
  }
}

ActOnBehalfOfController.$inject = [
  "$state",
  "$rootScope",
  "$scope",
  "UserProfileService",
  "UserInfoService",
  "FxpLoggerService",
  "FxpMessageService",
  "ActOnBehalfOfHelper",
  "OBOUserService",
  "FxpContextService",
  "FxpTelemetryContext",
  "FxpConfigurationService",
  "DashboardService",
  "FxpGlobalStoreService",
];
